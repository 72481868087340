import {
	IClient,
	IClientHistoryItem,
} from '../../../../../../../core/interfaces';

import { ClientService } from '../../services/client.service';
import { Component } from '@angular/core';
import { HistoryItemComponent } from './history-item/history-item.component';
import { MatDialog } from '@angular/material/dialog';
import { sortBy } from 'lodash';

@Component({
	selector: 'app-history',
	templateUrl: './history.component.html',
	styleUrls: ['./history.component.scss'],
})
export class HistoryComponent {
	public client: IClient;

	public constructor(
		public dialog: MatDialog,
		private clientService: ClientService,
	) {
		this.clientService.client$.subscribe(
			client => (this.client = client),
		);
	}

	public get history(): IClientHistoryItem[] {
		return this.client && this.client.history
			? sortBy(this.client.history, h => h.createdAt).reverse()
			: [];
	}

	public viewItem(item: IClientHistoryItem): void {
		const modalRef = this.dialog.open(HistoryItemComponent);
		modalRef.componentInstance.item = item;
		modalRef.componentInstance.init();
	}
}
