import {
	IEmail,
	ISentSMS,
} from '../../../../../../../../core/notification/interfaces';

import { ClientService } from '../../../services/client.service';
import { Component } from '@angular/core';
import { GoogleService } from '../../../services/google.service';
import { IClientHistoryItem } from '../../../../../../../../core/interfaces';

@Component({
	selector: 'app-history-item',
	templateUrl: './history-item.component.html',
	styleUrls: ['./history-item.component.scss'],
})
export class HistoryItemComponent {
	public item: IClientHistoryItem;

	public email: IEmail;
	public sms: ISentSMS;

	constructor(
		private googleService: GoogleService,
		private clientService: ClientService,
	) {}

	public async init(): Promise<void> {
		if (!this.item) {
			return;
		}

		let payload: any;

		switch (this.item.type) {
			case 'EMAIL':
				payload = JSON.parse(this.item.payload);
				this.email = await this.googleService.getEmail(payload.id);
				break;
			case 'SMS':
				payload = JSON.parse(this.item.payload);
				this.sms = await this.clientService.getSmsById(payload.id);
				break;
			default:
		}
	}
}
