<h2 mat-dialog-title>{{ item?.name }}</h2>

<div class="content">
	<div *ngIf="item?.type === 'EMAIL'">
		<div class="info">
			<div>
				<b>De</b> : {{ email?.from || '...' }}</div>
			<div>
				<b>À</b> : {{ email?.to || '...' }}</div>
			<div>
				<b>Sujet</b> : {{ email?.subject || '...' }}</div>
			<div>
				<b>Le</b> : {{ item.createdAt | date:'short' }}</div>
		</div>
		<div class="divider"></div>
		<div [innerHTML]="(email?.body || '...') | safeHtml"></div>
	</div>
	<div *ngIf="item?.type === 'SMS'">
		<div class="info">
			<div>
				<b>De</b> : {{ sms?.from || '...' }}</div>
			<div>
				<b>À</b> : {{ sms?.to || '...' }}</div>
			<div>
				<b>Le</b> : {{ item.createdAt | date:'short' }}</div>
		</div>
		<div class="divider"></div>
		<div>{{ sms?.body || '...' }}</div>
	</div>
</div>

<div mat-dialog-actions>
	<button mat-button mat-dialog-close>Retour</button>
</div>