<button mat-button (click)="close()" class="top">X</button>

<core-proposal [proposal]="proposal">
	<div class="response green" *ngIf="isApproval()">
		Le client a accepté cette proposition.
	</div>
	<div class="response red" *ngIf="isRejection()">
		Le client a refusé cette proposition.
	</div>
	<div class="justification" *ngIf="isApproval() || isRejection()">
		<b>Justification :</b> {{ proposal.justification }}
	</div>
</core-proposal>

<div class="bottom">
	<button mat-raised-button (click)="close()">Fermer</button>
</div>
