import { Component, Inject } from '@angular/core';
import {
	IProposal,
	ProposalStates,
} from '../../../../../../../../core/interfaces';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

interface IProposalDialogData {
	proposal: IProposal;
}

@Component({
	selector: 'app-proposal-dialog',
	templateUrl: './proposal-dialog.component.html',
	styleUrls: ['./proposal-dialog.component.scss'],
})
export class ProposalDialogComponent {
	public proposal: IProposal;

	public constructor(
		@Inject(MAT_DIALOG_DATA) dialogData: IProposalDialogData,
		private dialogRef: MatDialogRef<ProposalDialogComponent>,
	) {
		this.proposal = dialogData.proposal;
	}

	public close(): void {
		this.dialogRef.close();
	}

	public isApproval(): boolean {
		return this.proposal.state === ProposalStates.SELECTED;
	}

	public isRejection(): boolean {
		return this.proposal.state === ProposalStates.REJECTED;
	}
}
