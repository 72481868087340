import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable()
export class UserService {
	constructor(private http: HttpClient, private auth: AuthService) {}

	public async getSignature(): Promise<string> {
		return this.http
			.get<{ signature: string }>(
				`/api/users/me/signature`,
				this.auth.authorizedHeader,
			)
			.pipe(map(data => data.signature))
			.toPromise();
	}
}
