<table *ngIf="client && !selectedProposal">
	<tbody>
		<tr *ngFor="let proposal of proposals">
			<td>
				<b>{{ proposal.name }}</b>
				<mat-chip-list>
					<mat-chip *ngFor="let chip of chipMap[proposal.state] || []" [color]="chip.color" selected="true">
						{{chip.name}}
					</mat-chip>
				</mat-chip-list>
			</td>
			<td>
				<button mat-button (click)="display(proposal)">Voir</button>
				<button mat-button (click)="update(proposal)">Modifier</button>
				<button mat-button (click)="delete(proposal)">Supprimer</button>
			</td>
		</tr>
		<tr>
			<td colspan="2">
				<button mat-raised-button color="primary" (click)="create()">Nouvelle proposition</button>
			</td>
		</tr>
	</tbody>
</table>

<div *ngIf="client && selectedProposal">
	<app-proposal-form [client]="client" [proposal]="selectedProposal" (saveClicked)="onSave($event)" (cancelClicked)="selectedProposal = null"
	 (previewClicked)="display($event)"></app-proposal-form>
</div>