import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output,
} from '@angular/core';

import { IResult } from '../../../../../../../core/interfaces';
import { tooltipPositions } from '../../../config/globals';

export interface IResultAction {
	name: string;
	displayName: string;
	icon: string;
}

export interface IResultActionClickEvent {
	action: string;
	result: IResult;
}

const hostToPng = {
	'heycar.fr': 'heycarfr.png',
	'www.lacentrale.fr': 'lacentrale.png',
}

@Component({
	selector: 'app-scrapper-results',
	templateUrl: './scrapper-results.component.html',
	styleUrls: ['./scrapper-results.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScrapperResultsComponent {
	@Input() public results: IResult[];
	@Input() public actions: IResultAction[] = [];
	@Output() public markAsReadClick: EventEmitter<IResult>;
	@Output() public actionClick: EventEmitter<IResultActionClickEvent>;

	public positions = tooltipPositions;

	public constructor() {
		this.markAsReadClick = new EventEmitter();
		this.actionClick = new EventEmitter();
	}

	public getImgSrc(result: IResult): string {
		const host = new URL(result.url).host;
		const img = hostToPng[host];
		return img ? `/assets/icons/${img}` : '';
	}
}
