<div *ngIf="client?.buyForm" class="buy-form">
	<h3>Formulaire d'achat</h3>
	<div class="information">
		<h4>Données</h4>
		<table>
			<tbody>
				<tr>
					<td>Budget</td>
					<td>{{ client.buyForm.budget }}</td>
				</tr>
				<tr *ngIf="client.buyForm.kilometersPerYear">
					<td>Kilomètres par an</td>
					<td>{{ client.buyForm.kilometersPerYear }}</td>
				</tr>
				<tr *ngIf="client.buyForm.seatNumber">
					<td>Nombre de siège</td>
					<td>{{ client.buyForm.seatNumber }}</td>
				</tr>
				<tr *ngIf="client.buyForm.otherEquipment">
					<td>Autre équipement</td>
					<td>{{ client.buyForm.otherEquipment }}</td>
				</tr>
				<tr *ngIf="brandOrModels">
					<td>Marques ou modèles</td>
					<td>{{ brandOrModels }}</td>
				</tr>
				<tr>
					<td>Commentaire</td>
					<td>{{ client.buyForm.comment }}</td>
				</tr>
				<tr>
					<td>Créé le</td>
					<td>{{ client.buyForm.createdAt | date:'short' }}</td>
				</tr>
				<tr>
					<td>Modifié le</td>
					<td>{{ client.buyForm.updatedAt | date:'short' }}</td>
				</tr>
			</tbody>
		</table>
	</div>
	<div class="ratingQuestions" *ngIf="client.buyForm.ratingQuestions?.length">
		<h4>Notations</h4>
		<table>
			<tbody>
				<tr *ngFor="let ratingQ of client.buyForm.ratingQuestions">
					<td>{{ ratingQ.criteria.name }}</td>
					<td>
						{{ratingQ.rating}} / 10
					</td>
				</tr>
			</tbody>
		</table>
	</div>
	<div class="criterias" *ngIf="client.buyForm.equipments?.length + client.buyForm.tripTypes?.length + client.buyForm.usageTypes?.length">
		<h4>Critères</h4>
		<table>
			<tbody>
				<tr>
					<td>Équipement(s)</td>
					<td>
						<mat-chip-list>
							<mat-chip *ngFor="let equipment of client.buyForm.equipments">{{ equipment.name }}</mat-chip>
						</mat-chip-list>
					</td>
				</tr>
				<tr>
					<td>Type(s) de trajet</td>
					<td>
						<mat-chip-list>
							<mat-chip *ngFor="let tripType of client.buyForm.tripTypes">{{ tripType.name }}</mat-chip>
						</mat-chip-list>
					</td>
				</tr>
				<tr>
					<td>Utilisation(s)</td>
					<td>
						<mat-chip-list>
							<mat-chip *ngFor="let usageType of client.buyForm.usageTypes">{{ usageType.name }}</mat-chip>
						</mat-chip-list>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>
<div *ngIf="client?.buyForm?.oldCarKilometers || client?.buyForm?.oldCarNumberPlate">
	<h3>Reprise du véhicule</h3>
	<div class="information">
		<table>
			<tbody>
				<tr>
					<td>Plaque</td>
					<td>{{ client.buyForm.oldCarNumberPlate }}</td>
				</tr>
				<tr>
					<td>Kilométrage</td>
					<td>{{ client.buyForm.oldCarKilometers }}</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>
<div *ngIf="client?.sellForm">
	<h3>Formulaire de vente</h3>
	<div class="information">
		<h4>Données</h4>
		<table>
			<tbody>
				<tr>
					<td>Marque</td>
					<td>{{ client.sellForm.brand }}</td>
				</tr>
				<tr>
					<td>Modèle</td>
					<td>{{ client.sellForm.model }}</td>
				</tr>
				<tr>
					<td>Kilométrage</td>
					<td>{{ client.sellForm.kilometers }}</td>
				</tr>
				<tr>
					<td>Créé le</td>
					<td>{{ client.sellForm.createdAt | date:'short' }}</td>
				</tr>
				<tr>
					<td>Modifié le</td>
					<td>{{ client.sellForm.updatedAt | date:'short' }}</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>
<div *ngIf="!client?.buyForm" class="buy-form">
	Aucune information issue du formulaire.
</div>
