import {
	Component,
	ElementRef, forwardRef, Input,
	OnChanges, SimpleChanges,
	ViewChild
} from '@angular/core';
import {
	ControlValueAccessor,
	FormControl,
	NG_VALUE_ACCESSOR
} from '@angular/forms';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject } from 'rxjs';
import { debounceTime, map, shareReplay, startWith, switchMap } from 'rxjs/operators';

import { ScrapperService } from '../../services/scrapper.service';

@Component({
	selector: 'app-version-select',
	templateUrl: './version-select.component.html',
	styleUrls: ['./version-select.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => VersionSelectComponent),
			multi: true,
		},
	],
})
export class VersionSelectComponent
	implements ControlValueAccessor, OnChanges {
	@Input() public model: string;
	@Input() public brand: string;

	@ViewChild('versionInput', { static: true }) public versionInput: ElementRef;

	public versionTokenSearch: FormControl;
	public versionTokens$ = new BehaviorSubject<string[]>([]);
	public versionTokenOptions$: Observable<string[]>;
	public visibleVersionTokenOptions$: Observable<string[]>;

	private _onChangeFunc: () => void;

	private _model$ = new ReplaySubject<string>();
	private _brand$ = new ReplaySubject<string>();

	constructor(private scrapperService: ScrapperService) {
		this.versionTokenSearch = new FormControl('');

		this.versionTokenOptions$ = combineLatest([
			this._brand$,
			this._model$,
		]).pipe(
			debounceTime(300),
			switchMap(([brand, model]) => brand && model
				? this.scrapperService.getVersionTagCloud(
					brand,
					model,
				)
				: []
			),
			shareReplay({ refCount: true, bufferSize: 1 })
		);

		this.visibleVersionTokenOptions$ = combineLatest([
			this.versionTokenOptions$,
			this.versionTokens$,
			this.versionTokenSearch.valueChanges.pipe(startWith('')),
		]).pipe(
			map(([options, selected, search]) => options
				.filter(token => !selected.includes(token))
				.filter(
					token =>
						!(search || '').trim() ||
						token.toUpperCase().includes(search.toUpperCase()),
				)
			)
		);
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.brand) {
			this._brand$.next(this.brand);
		}
		if (changes.model) {
			this._model$.next(this.model);
		}
	}

	public selectVersionToken(token: string): void {
		this.versionTokens$.next([...this.versionTokens$.value, token]);

		this.notifyChanges();

		this.versionTokenSearch.setValue('');
		this.versionInput.nativeElement.blur();
	}

	public unselectVersionToken(token: string): void {
		this.versionTokens$.next(this.versionTokens$.value.filter(t => t !== token));
		this.notifyChanges();
	}

	public writeValue(obj: { value: string } | string): void {
		const value = typeof obj === 'string' ? obj : obj?.value;
		this.versionTokens$.next(value?.split(' ') || []);
	}

	public registerOnChange(fn: (newValue: string) => void): void {
		this._onChangeFunc = () => {
			fn(
				this.versionTokens$.value.length
					? this.versionTokens$.value.join(' ')
					: null,
			);
		};
	}
	public registerOnTouched(): void {
		// Not implemented
	}
	public setDisabledState(isDisabled: boolean): void {
		isDisabled
			? this.versionTokenSearch.disable()
			: this.versionTokenSearch.enable();
	}

	private notifyChanges(): void {
		if (!!this._onChangeFunc) {
			this._onChangeFunc();
		}
	}
}
