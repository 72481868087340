import * as moment from 'moment';

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { IWebsiteResult } from '../../../../../../../core/interfaces';
import { reduce } from 'lodash';
import { tooltipPositions } from '../../../config/globals';

@Component({
	selector: 'app-website-scrapper-results',
	templateUrl: './website-scrapper-results.component.html',
	styleUrls: ['./website-scrapper-results.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WebsiteScrapperResultsComponent {
	@Input() public results: IWebsiteResult[];

	public positions = tooltipPositions;

	public get resultsNumber(): number {
		return reduce(
			this.results,
			(memo, result) => memo + result.totalResultNumber,
			0,
		);
	}

	public getHeaderText(websiteResult: IWebsiteResult): string {
		const resultNumber = websiteResult.results.length;

		const mainText = `${websiteResult.website}`;

		const fractionText =
			resultNumber === websiteResult.totalResultNumber
				? '' + resultNumber
				: `${resultNumber}/${websiteResult.totalResultNumber}`;

		const unwantedText = !!websiteResult.unwantedResults
			? `${websiteResult.unwantedResults} ` +
				(websiteResult.unwantedResults > 1
					? 'résultats écartés'
					: 'résultat écarté')
			: '';

		const displayedResultText = ` (${fractionText} ${resultNumber > 1
			? 'résultats affichés'
			: 'résultat affiché'}`;

		return !!resultNumber
			? `${mainText} ${displayedResultText}${!!unwantedText
					? ` | ${unwantedText}`
					: ''})`
			: mainText + (!!unwantedText ? ` (${unwantedText})` : '');
	}

	public getTooltip(websiteResult: IWebsiteResult): string {
		const duration = moment
			.duration(websiteResult.duration)
			.format('m[min] s[s] SS[ms]', { forceLength: true });

		return `exécuté en ${duration}`;
	}

	public getImgSrc(website: string): string {
		const sanitized = website.toLowerCase().replace(/ /g, '');

		return `/assets/icons/${sanitized}.png`;
	}
}
