import { Component, forwardRef } from '@angular/core';
import {
	ControlValueAccessor,
	FormControl,
	NG_VALUE_ACCESSOR,
} from '@angular/forms';
import {
	ICityPossibility,
	ICityWithCoordinates,
} from '../../../../../../../core/scrapper/interfaces';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';

import { GoogleService } from '../../services/google.service';

@Component({
	selector: 'app-place-select',
	templateUrl: './place-select.component.html',
	styleUrls: ['./place-select.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => PlaceSelectComponent),
			multi: true,
		},
	],
})
export class PlaceSelectComponent implements ControlValueAccessor {
	public options: ICityPossibility[];
	public value: ICityWithCoordinates;
	public search: FormControl;

	private onChangeFunc: (place: ICityWithCoordinates) => void;

	public constructor(private googleService: GoogleService) {
		this.options = [];
	}

	public ngOnInit(): void {
		this.search = new FormControl();

		this.search.valueChanges
			.pipe(
				filter(search => typeof search === 'string'),
				distinctUntilChanged(),
				debounceTime(300),
			)
			.subscribe(search => {
				if (!search) {
					this.onValueChange(null);
				}

				this.updateOptions(search);
			});

		this.search.valueChanges
			.pipe(filter(search => typeof search === 'object'))
			.subscribe((search: ICityPossibility) => this.onValueChange(search));
	}

	public writeValue(obj: ICityWithCoordinates): void {
		this.value = obj;

		if (!!obj) {
			const name = obj.name.replace(new RegExp('^' + obj.postalCode + ' '), '');

			this.search.setValue(name, { emitEvent: false });
		}
	}
	public registerOnChange(fn: any): void {
		this.onChangeFunc = fn;
	}
	public registerOnTouched(): void {
		// Not implemented
	}

	public async onValueChange(value: ICityPossibility): Promise<void> {
		// Update text input
		this.search.setValue(!!value ? value.name : '', { emitEvent: false });

		this.value = !!value
			? await this.googleService.getPlaceCoordinate(value.placeId)
			: null;

		if (!!this.onChangeFunc) {
			this.onChangeFunc(this.value);
		}
	}

	private async updateOptions(search: string): Promise<void> {
		this.options = [];
		const sanitizedSearch = !!search ? search.trim() : '';

		if (!!sanitizedSearch) {
			this.options = await this.googleService.getPlacePredictions(
				sanitizedSearch,
			);
		}
	}
}
