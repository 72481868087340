import { IClient, UserScope } from './interfaces';
import { IAuthenticatedUser, IPrincipal } from './users/interfaces';

export const ADMIN_GROUP_NAME = 'TGC-Admin';
export const USER_GROUP_NAME = 'TGC-User';

export class ClientStates {
	public static NEW = 'NEW';
	public static REJECTED = 'REJECTED';
	public static INPROGRESS = 'INPROGRESS';
	public static DONE = 'DONE';
}

export const ADMIN_USER = {
	sub: '0',
	name: 'TGC Admin',
	email: 'contact@thegoodcar.fr',
} as IAuthenticatedUser;

export const ADMIN_PRINCIPAL: IPrincipal = {
	name: 'TGC Admin',
	email: 'contact@thegoodcar.fr',
	legalEntity: null,
	scope: UserScope.Noop,
};

export function getAdminUserByClient(client: IClient): IPrincipal {
	return {
		...ADMIN_PRINCIPAL,
		email: client.legalEntity.owner.email,
	};
}
