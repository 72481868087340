<mat-list *ngIf="results?.length; else noResult">
	<mat-list-item *ngFor="let result of results">
		<img mat-list-icon [attr.src]="getImgSrc(result)" />
		<h4 mat-line>
			<span
				cdkOverlayOrigin
				#trigger="cdkOverlayOrigin"
				(mouseenter)="result.displayImage = true"
				(mouseleave)="result.displayImage = false">
				{{ result.name }}
			</span>
			<ng-template
				cdkConnectedOverlay
				[cdkConnectedOverlayOrigin]="trigger"
				[cdkConnectedOverlayOpen]="result.displayImage"
				[cdkConnectedOverlayPositions]="positions"
			>
				<div class="result-img" [style.background-image]="'url(' + result.imageUrl + ')'"></div>
			</ng-template>
		</h4>
		<p mat-line>
			<span>{{ result.price | currency:'EUR' }}</span>
			<span class="divider">|</span>
			<span class="actions">
				<a target="_blank" [href]="result.url">voir</a>
				<a *ngFor="let action of actions" (click)="actionClick.emit({ action: action.name, result: result })" class="action" [ngClass]="action.name">
					{{ action.displayName }}
					<mat-icon class="icon">{{ action.icon }}</mat-icon>
				</a>
			</span>
		</p>
		<p mat-line>

		</p>
	</mat-list-item>
</mat-list>
<ng-template #noResult>Aucun résultat</ng-template>
