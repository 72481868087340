import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { AdminComponent } from './admin.component';
import { BackendModule } from './backend/backend.module';
import { ClientsModule } from './clients/clients.module';
import { DealershipsModule } from './dealerships/dealerships.module';
import { LoginComponent } from './login/login.component';
import { RecommandationsModule } from './recommandations';
import { ScrapperModule } from './scrapper/scrapper.module';
import { ClientService } from './services/client.service';
import { CopyPasteService } from './services/copy-paste.service';
import { DealershipsService } from './services/dealerships.service';
import { RecommandationsService } from './services/recommandations.service';
import { UserService } from './services/user.service';

@NgModule({
	imports: [
		BackendModule,
		CommonModule,
		ClientsModule,
		DealershipsModule,
		RecommandationsModule,
		MatButtonModule,
		MatToolbarModule,
		RouterModule,
		ScrapperModule,
	],
	declarations: [AdminComponent, LoginComponent],
	providers: [ClientService, DealershipsService, UserService, CopyPasteService, RecommandationsService],
})
export class AdminModule {}
