import { Routes } from '@angular/router';
import { AlertsComponent } from './alerts/alerts.component';
import { EmailComponent } from './email/email.component';
import { FormsComponent } from './forms/forms.component';
import { HistoryComponent } from './history/history.component';
import { HomeComponent } from './home/home.component';
import { ProposalListComponent } from './proposal/proposal-list/proposal-list.component';

export const clientRoutes: Routes = [
	{ path: '', pathMatch: 'full', redirectTo: 'home' },
	{
		path: 'home',
		component: HomeComponent,
	},
	{
		path: 'alerts',
		component: AlertsComponent,
	},
	{
		path: 'forms',
		component: FormsComponent,
	},
	{
		path: 'proposals',
		component: ProposalListComponent,
	},
	{
		path: 'emails',
		component: EmailComponent,
	},
	{
		path: 'history',
		component: HistoryComponent,
	},
];
