import { Router } from '@angular/router';

export function redirectToLogin(router: Router): void {
	try {
		localStorage.setItem('url_before_login', window.location.pathname);
		localStorage.setItem('params_before_login', window.location.search);
	} catch (e) {
		console.warn('Error while using localStorage');
	}

	router.navigate(['login'], { replaceUrl: true });
}
