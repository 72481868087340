import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { chain } from 'lodash';
import { filter, take } from 'rxjs/operators';
import { IClient } from '../../../../../../../core/interfaces';
import {
	IEmail,
	INotificationTemplate,
} from '../../../../../../../core/notification/interfaces';
import {
	FullRecapNormalNotificationTemplate,
	FullRecapPoliteNotificationTemplate,
	NewClientNormalNotificationTemplate,
	NewClientPoliteNotificationTemplate,
	RefusedClientNormalNotificationTemplate,
	RefusedClientPoliteNotificationTemplate,
	SimpleRecapNormalNotificationTemplate,
	SimpleRecapPoliteNotificationTemplate,
} from '../../../../../../../core/notification/models';
import { formatEmailBody } from '../../../../../../../core/notification/utils';
import { AuthService } from '../../services/auth.service';
import { ClientService } from '../../services/client.service';
import { UserService } from '../../services/user.service';

@Component({
	selector: 'app-email',
	templateUrl: './email.component.html',
	styleUrls: ['./email.component.scss'],
})
export class EmailComponent {
	public client: IClient;

	public templates: INotificationTemplate[];
	public selectedTemplate: INotificationTemplate;
	public subject: string;
	public content: string;
	public displayPreview: boolean;

	public isLoading: boolean;

	private signature: string;

	constructor(
		private clientService: ClientService,
		private userService: UserService,
		private auth: AuthService,
		private router: Router,
		private activatedRoute: ActivatedRoute,
	) {
		this.templates = [
			new NewClientNormalNotificationTemplate(),
			new RefusedClientNormalNotificationTemplate(),
			new SimpleRecapNormalNotificationTemplate(),
			new FullRecapNormalNotificationTemplate(),
			new NewClientPoliteNotificationTemplate(),
			new RefusedClientPoliteNotificationTemplate(),
			new SimpleRecapPoliteNotificationTemplate(),
			new FullRecapPoliteNotificationTemplate(),
		];

		this.displayPreview = false;
		this.isLoading = false;

		this.clientService.client$
			.pipe(
				filter(c => !!c),
				take(1),
			)
			.subscribe(client => {
				this.client = client;
				this.init();
			});
	}

	public async ngOnInit(): Promise<void> {
		this.signature = await this.userService.getSignature();
	}

	public init(): void {
		this.selectedTemplate = null;
		this.subject = null;
		this.content = null;
		this.displayPreview = false;
	}

	public onTemplateSelection(template: INotificationTemplate): void {
		this.subject = template.getSubject(this.client, this.auth.user);
		this.content = template.getContent(this.client, this.auth.user);
	}

	public get preview(): string {
		return formatEmailBody(
			(this.content || '') +
				(this.signature ? '\n' + this.signature : ''),
		);
	}

	public get remainingMissingRemplacement(): string[] {
		if (!this.content) {
			return [];
		}

		return chain(this.content.match(/\*.*?\*/g))
			.map(m => m.replace(/\*/g, ''))
			.value();
	}

	public async send(): Promise<void> {
		const email = {
			subject: this.subject,
			body: formatEmailBody(this.content),
			model: this.selectedTemplate ? this.selectedTemplate.name : null,
		} as IEmail;

		try {
			this.isLoading = true;

			await this.clientService.sendEmail(this.client.id, email);
		} catch (e) {
			throw e;
		} finally {
			this.isLoading = false;
		}

		this.init();

		this.router.navigate(['../history'], {
			relativeTo: this.activatedRoute,
		});
	}
}
