import { AuthService } from '../services/auth.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { IAllAlertsUpdateProcess } from '../../../../../../core/interfaces';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable()
export class BackendService {
	public allAlertsUpdateProcess$: BehaviorSubject<IAllAlertsUpdateProcess>;
	public runInProgress$: Observable<boolean>;

	constructor(
		private authService: AuthService,
		private httpClient: HttpClient,
	) {
		this.allAlertsUpdateProcess$ = new BehaviorSubject(null);

		this.runInProgress$ = this.allAlertsUpdateProcess$.pipe(
			map(process => !!process && !process.endDate),
		);
	}

	public async runAllAlertsUpdateProcess(): Promise<void> {
		let process = await this.httpClient
			.post<IAllAlertsUpdateProcess>(
				`/api/backend/updateAllAlerts`,
				null,
				this.authService.authorizedHeader,
			)
			.toPromise();

		this.allAlertsUpdateProcess$.next({ ...process });

		while (!process.endDate) {
			process = await this.httpClient
				.get<IAllAlertsUpdateProcess>(
					`/api/backend/allAlertsUpdateProcess/${process.id}`,
					this.authService.authorizedHeader,
				)
				.toPromise();

			this.allAlertsUpdateProcess$.next({ ...process });
			await this.waitMilliseconds(2000);
		}
	}

	public async getAllAlertsUpdateProcessLogs(): Promise<
		IAllAlertsUpdateProcess[]
	> {
		return this.httpClient
			.get<IAllAlertsUpdateProcess[]>(
				'/api/logs/allAlertsUpdateProcess',
				this.authService.authorizedHeader,
			)
			.toPromise();
	}

	private waitMilliseconds(ms: number): Promise<void> {
		return new Promise<void>(resolve => {
			setTimeout(() => resolve(), ms);
		});
	}
}
