import { apply, filter, join, juxt, map, path, pipe, prop, props } from 'ramda';

import { IFilter } from '../../../../../../../core/scrapper/interfaces';

const friendlyJoin = (beforeMinText: string, afterMaxText: string) => (
	min: number,
	max: number,
) => {
	if (!min && !max) {
		return '';
	}
	if (!min) {
		return beforeMinText + max;
	}
	if (!max) {
		return afterMaxText + min;
	}

	return 'entre ' + min + ' et ' + max;
};

const friendlyJoinYears = friendlyJoin('avant ', 'après ');
const friendlyJoinKilometers = friendlyJoin('moins de ', 'plus de ');
const friendlyJoinPrices = friendlyJoin('moins de ', 'plus de ');

const getDateFragment = pipe(
	props(['minYear', 'maxYear']),
	apply(friendlyJoinYears),
);

const appendIfTruthy = (textToAppend: string) => (text: string) =>
	!!text ? text + textToAppend : text;
const appendKms = appendIfTruthy('km');
const appendEuros = appendIfTruthy('€');
const formatNumber = (num: number) =>
	num != null && num !== undefined ? num.toLocaleString() : num;

const getKilometersFragment = pipe(
	props<'minKilometers' | 'maxKilometers', number>(['minKilometers', 'maxKilometers']),
	map(formatNumber),
	apply(friendlyJoinKilometers),
	appendKms,
);

const getPricesFragment = pipe(
	props<'minPrice' | 'maxPrice', number>(['minPrice', 'maxPrice']),
	map(formatNumber),
	apply(friendlyJoinPrices),
	appendEuros,
);

function getPositionText(radius: number, _city: string, postalCode: string) {
	if (!_city || !postalCode) {
		return undefined;
	}

	const city = _city.replace(new RegExp('^' + postalCode + ' '), '');

	return 'à ' + (radius || 0) + 'km de ' + city + ' (' + postalCode + ')';
}

const getPositionFragment = pipe(
	juxt([
		path<number>(['position', 'radius']),
		path<string>(['position', 'coordinates', 'name']),
		path<string>(['position', 'coordinates', 'postalCode']),
	]),
	apply(getPositionText),
);

const filterAndJoinFragment = (joinText: string) =>
	pipe(filter(Boolean), join(joinText));

const getFirstPart = pipe(
	juxt([
		path(['brand', 'brand']),
		path(['brand', 'model']),
		prop('version'),
		prop('energy'),
		prop('gearbox'),
		prop('color'),
	]),
	filterAndJoinFragment(' '),
);

export function generateScrapperName(filter: IFilter): string {
	return pipe(
		juxt([
			getFirstPart,
			getDateFragment,
			getPricesFragment,
			getKilometersFragment,
			getPositionFragment,
		]),
		filterAndJoinFragment(', '),
	)(filter);
}
