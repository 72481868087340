<h3 *ngIf="!!results && !!results.length">Résultats ({{ resultsNumber }})</h3>
<mat-list>
	<ng-container *ngFor="let websiteResult of results; let last = last">
		<h3 mat-subheader>
			<span [matTooltip]="getTooltip(websiteResult)" matTooltipPosition="right">
				{{ getHeaderText(websiteResult) }} (<a target="_blank" [href]="websiteResult.url">voir</a>)
			</span>
		</h3>
		<ng-container *ngIf="!!websiteResult.results.length; else noResults">
			<mat-list-item *ngFor="let result of websiteResult.results">
				<img mat-list-icon [attr.src]="getImgSrc(websiteResult.website)" />
				<h4 mat-line>
					<span
						cdkOverlayOrigin
						#trigger="cdkOverlayOrigin"
						(mouseenter)="result.displayImage = true"
						(mouseleave)="result.displayImage = false">
						{{ result.name }}
					</span>
					<ng-template
						cdkConnectedOverlay
						[cdkConnectedOverlayOrigin]="trigger"
						[cdkConnectedOverlayOpen]="result.displayImage"
						[cdkConnectedOverlayPositions]="positions"
						>
						<div class="result-img" [style.background-image]="'url(' + result.imageUrl + ')'"></div>
					</ng-template>
					<a target="_blank" [href]="result.url">voir</a>
				</h4>
				<p mat-line>
					{{ result.price | currency:'EUR' }}
				</p>
			</mat-list-item>
		</ng-container>
		<ng-template #noResults>
			<mat-list-item>
				<img mat-list-icon [attr.src]="getImgSrc(websiteResult.website)" />
				<h4 mat-line>Aucun résultat</h4>
			</mat-list-item>
		</ng-template>
		<mat-divider *ngIf="!last"></mat-divider>
	</ng-container>
</mat-list>
