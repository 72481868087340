import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';

import { CommonModule } from '@angular/common';
import { ProposalModule as CoreProposalModule } from '../../../../../../core/proposal/proposal.module';
import { NgModule } from '@angular/core';
import { ProposalDialogComponent } from './proposal-dialog/proposal-dialog.component';
import { ProposalFormComponent } from './proposal-form/proposal-form.component';
import { ProposalImagePickerComponent } from './proposal-image-picker/proposal-image-picker.component';
import { ProposalListComponent } from './proposal-list/proposal-list.component';
import { ProposalService } from '../../services/proposal.service';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
	imports: [
		CommonModule,
		CoreProposalModule,
		MatButtonModule,
		MatChipsModule,
		MatInputModule,
		MatIconModule,
		MatDialogModule,
		ReactiveFormsModule,
	],
	declarations: [
		ProposalImagePickerComponent,
		ProposalListComponent,
		ProposalFormComponent,
		ProposalDialogComponent,
	],
	providers: [ProposalService],
	exports: [
		ProposalImagePickerComponent,
		ProposalListComponent,
		ProposalFormComponent,
	],
})
export class ProposalModule {}
