import * as moment from 'moment';

import { BackendService } from './backend.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Component } from '@angular/core';
import { IAllAlertsUpdateProcess } from '../../../../../../core/interfaces';

@Component({
	selector: 'app-backend',
	templateUrl: './backend.component.html',
	styleUrls: ['./backend.component.scss'],
})
export class BackendComponent {
	public now: Date;
	public allAlertsUpdateProcessLogs: IAllAlertsUpdateProcess[];

	constructor(private backendService: BackendService) {
		this.allAlertsUpdateProcessLogs = [];
	}

	public async ngOnInit(): Promise<void> {
		setInterval(() => {
			this.now = new Date();
		}, 1000);

		this.allAlertsUpdateProcessLogs = await this.backendService.getAllAlertsUpdateProcessLogs();
	}

	public get process(): BehaviorSubject<IAllAlertsUpdateProcess> {
		return this.backendService.allAlertsUpdateProcess$;
	}

	public get duration(): string {
		return moment
			.utc(
				moment(
					this.backendService.allAlertsUpdateProcess$.value.endDate || this.now,
				).diff(this.process.value.startDate),
			)
			.format('HH[h] mm[min] ss[s]');
	}

	public runNewProcess(): void {
		this.backendService.runAllAlertsUpdateProcess();
	}

	public get inProgress(): Observable<boolean> {
		return this.backendService.runInProgress$;
	}

	public getDurationOfLog(process: IAllAlertsUpdateProcess): string {
		const duration = moment.duration(
			moment(process.endDate).diff(process.startDate),
		);

		return `${duration.get('seconds')} s`;
	}
}
