import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { IDealership } from '../../../../../../core/interfaces';
import { AuthService } from './auth.service';

@Injectable()
export class DealershipsService {
	constructor(
		private httpClient: HttpClient,
		private authService: AuthService,
	) { }

	public getBrands(): Observable<string[]> {
		return this.httpClient.get<string[]>('/api/dealerships/brands', this.authService.authorizedHeader);
	}

	public getLocations(): Observable<{ name: string, number: number }[]> {
		return this.httpClient.get<{ name: string, number: number }[]>('/api/dealerships/locations', this.authService.authorizedHeader);
	}

	public getDealerships(brands: string[], locations: number[], search: string): Observable<IDealership[]> {
		let params = new HttpParams();

		if (brands && brands.length) {
			params = params.set('brands', brands.join(','));
		}
		if (locations && locations.length) {
			params = params.set('locations', locations.join(','));
		}
		if (search) {
			params = params.set('search', search);
		}

		if (!params.keys().length) {
			return of([]);
		}

		return this.httpClient.get<IDealership[]>('/api/dealerships', { ...this.authService.authorizedHeader, params });
	}
}
