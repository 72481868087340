import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ColDef, GridOptions } from 'ag-grid-community';
import { filter } from 'lodash';
import * as moment from 'moment';
import { BehaviorSubject, Observable, combineLatest, Subscription } from 'rxjs';
import {
  distinctUntilChanged,
  filter as rxFilter,
  map,
  switchMap
} from 'rxjs/operators';
import { ClientStates } from '../../../../../../../core/globals';
import {
  ILegalEntity,
  UserScope
} from '../../../../../../../core/interfaces';
import { AuthService } from '../../services/auth.service';
import { ClientService } from '../../services/client.service';
import { LegalEntitiesService } from '../../services/legal-entity.service';

export interface IValueText<T> {
	value: T;
	text: string;
	isNewState?: boolean;
}

export const STATE_VALUE_TEXTS = [
	{ value: ClientStates.NEW, text: 'Nouveau', isNewState: true },
	{ value: ClientStates.REJECTED, text: 'Rejeté' },
	{ value: ClientStates.INPROGRESS, text: 'En cours' },
	{ value: ClientStates.DONE, text: 'Terminé' },
];

@Component({
	selector: 'app-client-list',
	templateUrl: './client-list.component.html',
	styleUrls: ['./client-list.component.scss'],
})
export class ClientListComponent {
	public gridOptions: GridOptions;
	public defaultColDef: ColDef;
	public states: IValueText<string>[];
	public displayedState$: BehaviorSubject<string>;
	public newClientsNumber$: Observable<number>;

	public subscription: Subscription;
	public isSuperAdmin$: Observable<boolean>;

	public filteredLegalEntityIdFC: FormControl;
	public legalEntities$: Observable<ILegalEntity[]>;

	constructor(
		private clientService: ClientService,
		private route: ActivatedRoute,
		private router: Router,
		private authService: AuthService,
		private legalEntitiesService: LegalEntitiesService,
	) {
		this.subscription = new Subscription();

		this.initAgGrid();

		this.states = STATE_VALUE_TEXTS;
		this.filteredLegalEntityIdFC = new FormControl();

		this.legalEntities$ = this.legalEntitiesService.all$;

		this.displayedState$ = new BehaviorSubject(
			ClientStates.INPROGRESS,
		);

		this.route.queryParams
			.pipe(
				map(qp => qp.state),
				rxFilter(qp => !!qp),
				distinctUntilChanged(),
			)
			.subscribe(state => this.displayedState$.next(state));

		this.newClientsNumber$ = this.clientService.clients$.pipe(
			map(
				clients =>
					filter(clients, client => client.state === ClientStates.NEW)
						.length,
			),
		);

		this.isSuperAdmin$ = this.authService.principal$.pipe(
			map(principal => principal.scope === UserScope.AllLegalEntities),
		);

		const sub = this.filteredLegalEntityIdFC.valueChanges
			.pipe(switchMap(id => this.clientService.init(id)))
			.subscribe();

		this.subscription.add(sub);
		this.subscription.add(
			this.clientService.legalEntityId$.subscribe(legalEntityId =>
				this.filteredLegalEntityIdFC.setValue(
					legalEntityId,
					{
						emitEvent: false,
					},
				),
			),
		);
	}

	public onGridLoaded(): void {
		const clients$ = this.clientService.clients$.pipe(
			map(clients => clients.reverse()),
		);

		const sub = combineLatest([clients$, this.displayedState$])
			.pipe(
				map(([clients, state]) =>
					filter(clients, c => c.state === state),
				),
			)
			.subscribe(clients => {
				this.gridOptions.api.setRowData(clients);

				setTimeout(() => this.gridOptions.api.sizeColumnsToFit());
			});

		this.subscription.add(sub);
	}

	public updateSelectedState(newState: string): void {
		this.displayedState$.next(newState);

		this.router.navigate([], { queryParams: { state: newState } });
	}

	private initAgGrid(): void {
		this.defaultColDef = {
			sortable: true,
			filter: true,
			filterParams: {
				suppressAndOrCondition: true
			}
		}

		this.gridOptions = {
			rowSelection: 'single',
			overlayNoRowsTemplate: 'Aucun client à afficher',
		};

		this.gridOptions.columnDefs = [
			{
				headerName: 'Prénom',
				field: 'firstname',
			},
			{
				headerName: 'Nom',
				field: 'lastname',
			},
			{
				headerName: 'Téléphone',
				field: 'phone',
			},
			{
				headerName: 'Mail',
				field: 'email',
			},
			{
				headerName: 'Code postal',
				field: 'postalCode',
			},
			{
				headerName: 'Date de création',
				field: 'createdAt',
				filter: 'date',
				cellRenderer: row =>
					!!row.value
						? moment(row.value).format('DD/MM/YYYY HH:mm:ss')
						: '',
				sort: 'desc',
			},
			{
				headerName: 'Nouveau',
				field: 'newResultNumber',
				width: 60,
			},
		];

		this.gridOptions.onRowDoubleClicked = row =>
			this.router.navigate([row.data.id], { relativeTo: this.route });
	}

	public ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}
}
