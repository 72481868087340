import {
	IProposal,
	IProposalPicture,
} from '../../../../../../core/interfaces';
import { each, includes, map, partition } from 'lodash';

import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { IFilesToUpload } from '../clients/proposal/proposal-image-picker/proposal-image-picker.component';
import { Injectable } from '@angular/core';

@Injectable()
export class ProposalService {
	constructor(
		private httpClient: HttpClient,
		private authService: AuthService,
	) {}

	public async getProposalsForClient(
		clientId: string,
	): Promise<IProposal[]> {
		const searchResult = await this.httpClient
			.get<IProposal[]>(
				`/api/proposals/client/${clientId}`,
				this.authService.authorizedHeader,
			)
			.toPromise();

		return searchResult;
	}

	public async save(
		_proposal: IProposal,
		pictures: IFilesToUpload[],
	): Promise<IProposal> {
		return _proposal.id
			? await this._update(_proposal, pictures)
			: await this._create(_proposal, pictures);
	}

	public async remove(proposal: IProposal): Promise<IProposal> {
		return await this.httpClient
			.delete<IProposal>(
				`/api/proposals/${proposal.id}`,
				this.authService.authorizedHeader,
			)
			.toPromise();
	}

	private async sendNotification(
		proposal: IProposal,
	): Promise<void> {
		const url = `/api/proposals/${proposal.id}/sendNotification`;

		return await this.httpClient
			.post<void>(url, {}, this.authService.authorizedHeader)
			.toPromise();
	}

	private async uploadPictures(
		proposal: IProposal,
		files: IFilesToUpload[],
	): Promise<IProposal> {
		const config = this.authService.authorizedHeader;
		config.headers.append('Content-Type', 'multipart/form-data');

		const formData = new FormData();
		const [regularFiles, urlFiles] = partition(
			files,
			file => file.file !== null,
		);

		const urls = urlFiles.map(file => file.url);

		each(regularFiles, f =>
			formData.append('images', f.file, f.file.name),
		);

		formData.append('urls', JSON.stringify(urls));

		const isMainPicturesRegular = map(regularFiles, f => !!f.isMain);
		const isMainPicturesUrls = map(urlFiles, f => !!f.isMain);

		formData.append(
			'isMainPicturesRegular',
			JSON.stringify(isMainPicturesRegular),
		);
		formData.append(
			'isMainPicturesUrls',
			JSON.stringify(isMainPicturesUrls),
		);

		const url = `/api/proposals/${proposal.id}/uploadPictures`;

		return await this.httpClient
			.post<IProposal>(url, formData, config)
			.toPromise();
	}

	private async _create(
		_proposal: IProposal,
		pictures: IFilesToUpload[],
	): Promise<IProposal> {
		const proposal = await this.httpClient
			.post<IProposal>(
				'/api/proposals',
				_proposal,
				this.authService.authorizedHeader,
			)
			.toPromise();

		await this.uploadPictures(proposal, pictures);
		await this.sendNotification(proposal);

		return proposal;
	}

	private async _update(
		_proposal: IProposal,
		_pictures: IFilesToUpload[],
	): Promise<IProposal> {
		const { id } = _proposal;

		const indexToCreate = [] as number[];

		const picturesToCreate = _pictures.filter((picture, idx) => {
			if (!picture.id) {
				indexToCreate.push(idx);
			}

			return !picture.id;
		});

		const { pictures } = await this.uploadPictures(
			_proposal,
			picturesToCreate,
		);

		const newPictures = map(_pictures, (picture, index) => {
			return includes(indexToCreate, index)
				? pictures[index]
				: picture as IProposalPicture;
		});

		_proposal.pictures = newPictures;

		const proposal = await this.httpClient
			.put<IProposal>(
				`/api/proposals/${id}`,
				_proposal,
				this.authService.authorizedHeader,
			)
			.toPromise();

		return proposal;
	}
}
