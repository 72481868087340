import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { IRecommandation } from '../../../../../../core/interfaces';

@Injectable()
export class RecommandationsService {
	constructor(
		private httpClient: HttpClient,
		private authService: AuthService,
	) {}

	public getAll(): Promise<IRecommandation[]> {
		return this.httpClient
			.get<IRecommandation[]>(`/api/recommandations`, this.authService.authorizedHeader)
			.toPromise();
	}

	public create(data: FormData): Promise<IRecommandation> {
		return this.httpClient
			.post<IRecommandation>(`/api/recommandations`, data, this.authService.authorizedHeader)
			.toPromise();
	}

	public update(recommandationId: number, data: FormData): Promise<IRecommandation> {
		return this.httpClient
			.put<IRecommandation>(`/api/recommandations/${recommandationId}`, data, this.authService.authorizedHeader)
			.toPromise();
	}

	public delete(recommandationId: number): Promise<void> {
		return this.httpClient
			.delete<void>(`/api/recommandations/${recommandationId}`, this.authService.authorizedHeader)
			.toPromise();
	}
}
