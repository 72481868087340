import { BackendComponent } from './backend.component';
import { BackendService } from './backend.service';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { NgModule } from '@angular/core';

@NgModule({
	imports: [CommonModule, MatButtonModule],
	declarations: [BackendComponent],
	providers: [BackendService],
})
export class BackendModule {}
