<mat-toolbar>
	<span class="title">
		<button mat-button [routerLink]="['./home']">
			<h3>Client : {{ client?.firstname }} {{ client?.lastname }}</h3>
		</button>
	</span>
	<span class="tab">
		<button mat-button [routerLink]="['./forms']">Formulaires</button>
	</span>
	<span class="tab">
		<button mat-button [routerLink]="['./alerts']">Alertes</button>
	</span>
	<span class="tab">
		<button mat-button [routerLink]="['./proposals']">
			Propositions
		</button>
	</span>
	<span class="tab">
		<button mat-button [routerLink]="['./emails']">Email</button>
	</span>
	<span class="tab">
		<button mat-button [routerLink]="['./history']">Historique</button>
	</span>
	<mat-icon
		class="admin-icon"
		*ngIf="isSuperAdmin$ | async"
		(click)="displayAdmin = !displayAdmin"
	>
		settings_applications
	</mat-icon>
</mat-toolbar>
<ng-container *ngIf="client && displayAdmin">
	<div class="admin" *ngIf="isSuperAdmin$ | async">
		<b>Franchise</b>
		<mat-form-field>
			<mat-select
				[ngModel]="client.legalEntity.id"
				(ngModelChange)="setLegalEntity($event)"
			>
				<mat-option
					*ngFor="let legalEntity of legalEntities$ | async"
					[value]="legalEntity.id"
				>
					{{ legalEntity.name }}
				</mat-option>
			</mat-select>
		</mat-form-field>
	</div>
</ng-container>
<router-outlet></router-outlet>
