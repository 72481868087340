<div *ngIf="client">
	<div class="line">
		<mat-form-field>
			<mat-select placeholder="Modèle" (ngModelChange)="onTemplateSelection($event)" [(ngModel)]="selectedTemplate">
				<mat-option *ngFor="let template of templates" [value]="template">{{ template.name }}</mat-option>
			</mat-select>
		</mat-form-field>
	</div>
	<div class="divider"></div>
	<div class="line">
		<mat-form-field>
			<input matInput placeholder="Objet" [(ngModel)]="subject">
		</mat-form-field>
	</div>
	<div class="line">
		<mat-form-field>
			<textarea matInput placeholder="Message" [(ngModel)]="content" (ngModelChange)="displayPreview = displayPreview && !!$event"></textarea>
		</mat-form-field>
		<div *ngIf="remainingMissingRemplacement && remainingMissingRemplacement.length">
			<span class="template-helper">
				Les informations suivantes doivent être indiquées :
			</span>
			<mat-chip-list>
				<mat-chip *ngFor="let missingRemplacement of remainingMissingRemplacement">{{ missingRemplacement }}</mat-chip>
			</mat-chip-list>
		</div>
	</div>
	<div class="line">
		<mat-checkbox [(ngModel)]="displayPreview" [disabled]="!content">Afficher l'aperçu</mat-checkbox>
		<mat-card *ngIf="displayPreview">
			<div class="preview" [innerHTML]="preview | safeHtml"></div>
		</mat-card>
	</div>
	<div class="line">
		<button mat-raised-button (click)="send()" [disabled]="isLoading || !content || !!remainingMissingRemplacement.length">
			Envoyer
		</button>
		<mat-spinner *ngIf="isLoading"></mat-spinner>
		<button mat-raised-button (click)="init()">Réinitialiser</button>
	</div>
</div>
