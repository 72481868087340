import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { filter } from 'rxjs/operators';
import { IRecommandation } from '../../../../../../core/interfaces';
import { RecommandationsService } from '../services/recommandations.service';
import { RecommandationFormComponent } from './form/form.component';

@Component({
	selector: 'app-recommandations',
	templateUrl: 'recommandations.component.html',
	styleUrls: ['recommandations.component.scss'],
})

export class RecommandationsComponent implements OnInit {
	recommandations: Array<IRecommandation> = [];

	constructor(
		private _recommandationsService: RecommandationsService,
		private _dialog: MatDialog,
	) { }

	ngOnInit() {
		this._recommandationsService.getAll()
			.then(items => this.recommandations = items);
	}


	async deleteReco(reco: IRecommandation) {
		if (!window.confirm(`Êtes-vous sûr de bien vouloir supprimer ce bon plan ?`)) {
			return;
		}

		await this._recommandationsService.delete(reco.id);
		this.recommandations = this.recommandations.filter(r => r.id !== reco.id);
	}

	update(reco: IRecommandation) {
		this._dialog.open(RecommandationFormComponent, { data: reco })
			.afterClosed()
			.pipe(filter(r => !!r))
			.subscribe(reco => {
				this.recommandations = this.recommandations.map(r => r.id === reco.id ? reco : r);
			})
	}

	create() {
		this._dialog.open(RecommandationFormComponent, { data: null })
		.afterClosed()
		.pipe(filter(r => !!r))
		.subscribe(reco => {
			this.recommandations = [...this.recommandations, reco];
		})
	}
}
