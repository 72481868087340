import { RouterModule, Routes } from '@angular/router';
import { AdminComponent } from './admin/admin.component';
import { BackendComponent } from './admin/backend/backend.component';
import { ClientListComponent } from './admin/clients/client-list/client-list.component';
import { ClientComponent } from './admin/clients/client/client.component';
import { clientRoutes } from './admin/clients/clients.routes';
import { DealershipsComponent } from './admin/dealerships/dealerships.component';
import { LoginComponent } from './admin/login/login.component';
import { RecommandationsComponent } from './admin/recommandations';
import { ScrapperComponent } from './admin/scrapper/scrapper.component';
import { AppComponent } from './app.component';
import { CanActivateAdmin } from './core/canActivate/canActivateAdmin';
import { CanActivateCarAdvisor } from './core/canActivate/canActivateCarAdvisor';


const adminRoutes: Routes = [
	{
		path: '',
		component: AppComponent,
		children: [
			{
				path: '',
				component: AdminComponent,
				children: [
					{ path: '', pathMatch: 'full', redirectTo: 'clients' },
					{
						path: 'scrapper',
						children: [
							{ path: '', component: ScrapperComponent },
							{ path: ':searchId', component: ScrapperComponent },
						],
						canActivate: [CanActivateCarAdvisor],
					},
					{ path: 'login', component: LoginComponent },
					{
						path: 'dealerships',
						component: DealershipsComponent,
						canActivate: [CanActivateCarAdvisor]
					},
					{
						path: 'recommandations',
						component: RecommandationsComponent,
						canActivate: [CanActivateCarAdvisor]
					},
					{
						path: 'clients',
						children: [
							{ path: '', component: ClientListComponent },
							{
								path: ':id',
								component: ClientComponent,
								children: [...clientRoutes],
							},
						],
						canActivate: [CanActivateCarAdvisor],
					},
					{
						path: 'backend',
						children: [{ path: '', component: BackendComponent }],
						canActivate: [CanActivateAdmin],
					},
				],
			},
		],
	},
];

export const appRouting = RouterModule.forRoot(adminRoutes, { relativeLinkResolution: 'legacy' });
