<mat-toolbar color="primary">
	<span class="title">
		<button mat-button>
			<h3>TGC - Admin</h3>
		</button>
	</span>
	<span *ngIf="isCarAdvisor" class="tab">
		<button mat-button [routerLink]="['/clients']">Clients</button>
	</span>
	<span *ngIf="isCarAdvisor" class="tab">
		<button mat-button [routerLink]="['/scrapper']">Scrapper</button>
	</span>
	<span *ngIf="isCarAdvisor" class="tab">
		<button mat-button [routerLink]="['/dealerships']">Partenaires</button>
	</span>
	<span *ngIf="isCarAdvisor" class="tab">
		<button mat-button [routerLink]="['/recommandations']">Bons plans</button>
	</span>
	<span *ngIf="isSuperAdmin$ | async" class="tab">
		<button mat-button [routerLink]="['/backend']">
			Administration
		</button>
	</span>
	<span class="right tab">
		<button mat-button [routerLink]="['/login']">Mon compte</button>
	</span>
</mat-toolbar>
<router-outlet></router-outlet>
