<table>
	<thead>
		<tr>
			<th>Action</th>
			<th>Date</th>
			<th>Utilisateur</th>
			<th></th>
		</tr>
	</thead>
	<tbody>
		<tr *ngFor="let item of history">
			<td>{{ item.name }}</td>
			<td>{{ item.createdAt | date:'short' }}</td>
			<td>{{ item.user }}</td>
			<td><a (click)="viewItem(item)" *ngIf="item.type === 'EMAIL' || item.type === 'SMS'">voir</a></td>
		</tr>
	</tbody>
</table>