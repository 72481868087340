<h1>Administration</h1>
<h2>Exécutions de la mise à jour des alertes (10 dernières)</h2>
<table class="logs">
	<thead>
		<tr>
			<th>Date</th>
			<th>Durée</th>
			<th>Alertes</th>
			<th>En succès</th>
			<th>En erreur</th>
		</tr>
	</thead>
	<tbody>
		<tr *ngFor="let log of allAlertsUpdateProcessLogs">
			<td>{{ log.startDate | date:'medium' }}</td>
			<td>{{ getDurationOfLog(log) }}</td>
			<td>{{ log.allAlertNumber }}</td>
			<td>{{ log.doneAlertNumber }}</td>
			<td>{{ log.errorNumber }}</td>
		</tr>
	</tbody>
</table>
<h2>Mise à jour des alertes</h2>
<table *ngIf="process | async; let process" class="process">
	<tbody>
		<tr>
			<td>Début :</td>
			<td>{{ process.startDate | date:'medium' }}</td>
		</tr>
		<tr>
			<td>Durée :</td>
			<td>{{ duration }}</td>
		</tr>
		<tr>
			<td>Total :</td>
			<td>{{ process.allAlertNumber }}</td>
		</tr>
		<tr>
			<td>En succès :</td>
			<td>{{ process.doneAlertNumber }}</td>
		</tr>
		<tr>
			<td>En erreur :</td>
			<td>{{ process.errorNumber }}</td>
		</tr>
		<tr>
			<td>Restant(s) :</td>
			<td>{{ process.allAlertNumber - process.doneAlertNumber - process.errorNumber }}</td>
		</tr>
	</tbody>
</table>
<button mat-raised-button (click)="runNewProcess()" [disabled]="inProgress | async">Lancer manuellement</button>