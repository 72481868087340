import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import { RecommandationFormComponent } from './form/form.component';

import { RecommandationsComponent } from './recommandations.component';

@NgModule({
	imports: [CommonModule, MatButtonModule, MatChipsModule, MatCardModule, MatDialogModule, MatInputModule, MatFormFieldModule, ReactiveFormsModule],
	exports: [],
	declarations: [RecommandationsComponent, RecommandationFormComponent],
	providers: [],
})
export class RecommandationsModule { }
