import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
	IClient,
	IProposal,
} from '../../../../../../../../core/interfaces';
import { CopyPasteService } from '../../../services/copy-paste.service';

export interface IProposalForm {}

const defaultProposalInformation = `Prix affiché (Hors frais de mise en circulation) :
Mise en circulation :
Puissance fiscale :
Puissance réelle :
Couleur :
Garantie :
`;

const defaultEquipment = `

The Good Car n'est en rien responsable d'une erreur de l'annonce source. Mentions et photos non contractuelles.`;

@Component({
	selector: 'app-proposal-form',
	templateUrl: './proposal-form.component.html',
	styleUrls: ['./proposal-form.component.scss'],
})
export class ProposalFormComponent {
	@Input()
	public set proposal(prop: IProposal) {
		if (prop && prop.id) {
			this.form.patchValue(prop);
		}

		this._proposal = prop;
	}

	@Input() public client: IClient;

	@Output() public saveClicked: EventEmitter<any>;
	@Output() public cancelClicked: EventEmitter<void>;
	@Output() public previewClicked: EventEmitter<IProposal>;

	public form: FormGroup;

	public get copiedProposal(): IProposal {
		return this.copyPasteService.copiedProposal;
	}

	public get canCopy(): boolean {
		return this._proposal && !!this._proposal.id;
	}

	public get canPaste(): boolean {
		return (
			this.copiedProposal &&
			this.copiedProposal.id !== this._proposal.id
		);
	}

	private _proposal: IProposal;

	public constructor(
		private copyPasteService: CopyPasteService,
		private fb: FormBuilder,
	) {
		this.form = this.fb.group({
			name: fb.control(null, Validators.required),
			pictures: fb.control([], Validators.required),
			price: fb.control(null, Validators.required),
			year: fb.control(null, Validators.required),
			gearbox: fb.control(null, Validators.required),
			kilometers: fb.control(null, Validators.required),
			energy: fb.control(null, Validators.required),
			waranty: fb.control(null, Validators.required),
			equipments: fb.control(defaultEquipment, Validators.required),
			additionalInformation: fb.control(defaultProposalInformation),
		});

		this.saveClicked = new EventEmitter();
		this.cancelClicked = new EventEmitter();
		this.previewClicked = new EventEmitter();
	}

	public async save(): Promise<void> {
		const formValue = this.getFormValue();

		this.saveClicked.emit(formValue);
	}

	public cancel(): void {
		this.cancelClicked.emit();
	}

	public preview(): void {
		this.previewClicked.emit(this.form.value);
	}

	public copy(): void {
		this.copyPasteService.copiedProposal = this.getFormValue();
	}

	public paste(): void {
		const proposal = { ...this.copyPasteService.copiedProposal };

		delete proposal.id;

		proposal.pictures = proposal.pictures.map(
			p =>
				({
					id: p.id,
					url: p.url,
					name: p.name,
					file: null,
					isMain: p.isMain,
				}),
		);

		this.form.patchValue(proposal);
	}

	private getFormValue(): any {
		return {
			...this.form.value,
			client: { id: this.client.id },
			id: this._proposal.id,
			state: this._proposal.state,
		};
	}
}
