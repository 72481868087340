import {
	IValueText,
	STATE_VALUE_TEXTS,
} from '../client-list/client-list.component';

import { ClientService } from '../../services/client.service';
import { Component } from '@angular/core';
import { IClient } from '../../../../../../../core/interfaces';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
	public client: IClient;

	public isLoading: boolean = false;

	public states: IValueText<string>[];

	public constructor(private clientService: ClientService) {
		this.states = STATE_VALUE_TEXTS;

		this.clientService.client$.subscribe(
			client => (this.client = client),
		);
	}

	public async updateClientState(newState: string): Promise<void> {
		this.isLoading = true;

		try {
			this.client = await this.clientService.updateClient(
				'' + this.client.id,
				{
					state: newState,
				},
			);
		} catch (error) {
			console.log(error);
		}

		this.isLoading = false;
	}
}
