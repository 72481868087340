import { CanActivate, Router } from '@angular/router';

import { AuthService } from '../../admin/services/auth.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { redirectToLogin } from './can-activate.helper';

@Injectable()
export class CanActivateCarAdvisor implements CanActivate {
	public constructor(
		private authService: AuthService,
		private router: Router,
	) {}

	public canActivate(): Observable<boolean> | Promise<boolean> | boolean {
		const isCarAdvisor = this.authService.isCarAdvisor;

		if (!isCarAdvisor) {
			redirectToLogin(this.router);
		}

		return isCarAdvisor;
	}
}
