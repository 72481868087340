<div *ngIf="client">
	<h4>Contact</h4>
	<div class="client">
		<div>
			<i class="material-icons">call</i> {{ client.phone }}</div>
		<div>
			<i class="material-icons">mail_outline</i>
			<a [href]="'mailto:' + client.email">{{ client.email }}</a>
		</div>
		<div>
			<i class="material-icons">home</i> Code postal : {{ client.postalCode }}</div>
		<div>
			<i class="material-icons">today</i> Ajouté le {{ client.createdAt | date:'short' }}
			<span *ngIf="client.createdAt < client.updatedAt"> / Mis à jour le {{ client.updatedAt | date:'short' }}</span>
		</div>
	</div>
	<h4>État</h4>
	<div class="actions">
		<mat-button-toggle-group [value]="client.state">
			<mat-button-toggle *ngFor="let state of states" [value]="state.value" (click)="updateClientState(state.value)">{{ state.text }}</mat-button-toggle>
		</mat-button-toggle-group>
	</div>
</div>