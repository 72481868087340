import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ScrapperResultsModule } from '../scrapper-results/scrapper-results.module';
import { WebsiteScrapperResultsComponent } from './website-scrapper-results.component';
import { OverlayModule } from '@angular/cdk/overlay';

@NgModule({
	imports: [
		CommonModule,
		MatListModule,
		MatTooltipModule,
		ScrapperResultsModule,
		OverlayModule
	],
	declarations: [WebsiteScrapperResultsComponent],
	exports: [WebsiteScrapperResultsComponent],
})
export class WebsiteScrapperResultsModule {}
