import { IAuthenticatedUser } from '../users/interfaces';
import {
	INotificationClient,
	INotificationTemplate,
} from './interfaces';

function getFirstName(user: IAuthenticatedUser) {
	return !!user.user_metadata && !!user.user_metadata.displayName
		? user.user_metadata.displayName
		: user.name.split(' ')[0];
}

export abstract class ANotificationTemplate
	implements INotificationTemplate {
	public abstract name: string;
	public abstract getSubject(
		client?: INotificationClient,
		user?: IAuthenticatedUser,
	): string;
	public abstract getContent(
		client?: INotificationClient,
		user?: IAuthenticatedUser,
	): string;
}

export class NewClientPoliteNotificationTemplate extends ANotificationTemplate {
	public name = 'Prise en charge du client (vous)';

	public getContent(
		client: INotificationClient,
		user: IAuthenticatedUser,
	) {
		return `Bonjour ${client.firstname},

Je suis ${getFirstName(user)}, CarAdvisor chez The Good Car.

Merci pour l'intérêt que vous portez à notre service. Je vous recontacte suite au formulaire rempli sur notre site internet.

Quand auriez-vous un moment afin que l'on puisse vous appeler pour vous expliquer le fonctionnement du service et faire un point plus précis sur vos besoins ?

Nous aurions potentiellement des offres intéressantes à vous soumettre.

A bientôt,
`;
	}

	public getSubject(client: INotificationClient) {
		return `The Good Car pour ${client.firstname}`;
	}
}

export class NewClientNormalNotificationTemplate extends ANotificationTemplate {
	public name = 'Prise en charge du client (tu)';

	public getContent(
		client: INotificationClient,
		user: IAuthenticatedUser,
	) {
		return `Bonjour ${client.firstname},

Je suis ${getFirstName(user)}, CarAdvisor chez The Good Car.

Merci pour l'intérêt que tu portes à notre service. Je te recontacte suite au formulaire rempli sur notre site internet.

Quand aurais-tu un moment afin que l'on puisse t'appeler pour t'expliquer le fonctionnement du service et faire un point plus précis sur tes besoins ?

Nous aurions potentiellement des offres intéressantes à te soumettre.

A bientôt,
`;
	}

	public getSubject(client: INotificationClient) {
		return `The Good Car pour ${client.firstname}`;
	}
}

export class AutomaticNotificationTemplate extends ANotificationTemplate {
	public name = 'Information prise en charge du client automatique';

	public getContent(client: INotificationClient) {
		return `Bonjour ${client.firstname},

Votre demande a bien été prise en compte.

Un CarAdvisor va revenir vers vous dans les plus brefs délais.

Bien à vous,
`;
	}

	public getSubject(client: INotificationClient) {
		return `The Good Car pour ${client.firstname}`;
	}
}

export class RefusedClientPoliteNotificationTemplate extends ANotificationTemplate {
	public name = 'Refus de prise en charge du client (vous)';

	public getContent(
		client: INotificationClient,
		user: IAuthenticatedUser,
	) {
		return `Bonjour ${client.firstname},

Je suis ${getFirstName(user)}, CarAdvisor chez The Good Car.

Merci pour l’intérêt que vous portez à notre service.

Je vous recontacte suite au formulaire rempli sur notre site internet.

Malheureusement le service fonctionne pour les budgets d'un minimum de 8 000€. Si vous êtes en mesure d'augmenter votre budget afin d'atteindre ce seuil nous nous ferons un plaisir de vous aider.

Merci.

A bientôt,
`;
	}

	public getSubject(client: INotificationClient) {
		return `The Good Car pour ${client.firstname}`;
	}
}

export class RefusedClientNormalNotificationTemplate extends ANotificationTemplate {
	public name = 'Refus de prise en charge du client (tu)';

	public getContent(
		client: INotificationClient,
		user: IAuthenticatedUser,
	) {
		return `Bonjour ${client.firstname},

Je suis ${getFirstName(user)}, CarAdvisor chez The Good Car.

Merci pour l’intérêt que tu portes à notre service.

Je te recontacte suite au formulaire rempli sur notre site internet.

Malheureusement le service fonctionne pour les budgets d'un minimum de 8 000€. Si tu es en mesure d'augmenter ton budget afin d'atteindre ce seuil nous nous ferons un plaisir de t'aider.

Merci.

A bientôt,
`;
	}

	public getSubject(client: INotificationClient) {
		return `The Good Car pour ${client.firstname}`;
	}
}

export class SimpleRecapPoliteNotificationTemplate extends ANotificationTemplate {
	public name = 'Récapitulatif simple (vous)';

	public getContent(client: INotificationClient) {
		return `Bonjour ${client.firstname},

Voici comme convenu un récapitulatif sur le fonctionnement du service The Good Car.

- <b>Pour l'achat de votre future voiture</b> : nous allons rechercher pour vous la meilleure affaire grâce à notre algorithme, mener toutes les vérifications administratives nécessaires sur l'historique et l'entretien, avant de procéder à la négociation du prix.

Dans notre volonté d'aligner notre intérêt avec celui du client, nous captons une commission de 12% sur la remise que l'on arrive à obtenir par rapport au prix affiché.
Dans le cas où aucune (ou très faible) négociation n'est possible avec le vendeur, du fait par exemple d'un tarif déjà très agressif, le forfait minimum de base est de *montant*€ exceptionnellement ramené pour vous à *montant*€ afin que notre travail de recherches, de vérifications et d'accompagnement soit récompensé.

Nous avons également un partenariat avec un groupement de + de 300 mécaniciens partout en France qui peuvent se déplacer pour réaliser un rapport d'inspection complet sur le véhicule. Le coût supplémentaire de la prestation varie entre 99 et 149€ suivant la distance de déplacement depuis le centre de rattachement.

Une livraison à domicile ou sur le lieu de travail est également possible avec un tarif calculé en fonction de la distance parcourue.

- Si le client décide finalement suite à nos propositions correspondant au cahier des charges d’arrêter de faire appel à nos services en cours de route ou que pour une raison diverse l'échange s’arrête, on lui demande simplement *montant*€ qui couvrent le temps passé et toutes les démarches effectuées pour la recherche du véhicule.

<b><u>Rappel du cahier des charges :</u></b>

*informations supplémentaires*

<b>Pour lancer les opérations il suffit juste de me confirmer par retour de mail "Bon pour accord" que le cahier des charges et le fonctionnement du service vous conviennent.</b>

Merci.

A bientôt,
`;
	}

	public getSubject(client: INotificationClient) {
		return `Mail récapitulatif ${client.firstname} ${client.lastname} / The Good Car`;
	}
}

export class SimpleRecapNormalNotificationTemplate extends ANotificationTemplate {
	public name = 'Récapitulatif simple (tu)';

	public getContent(client: INotificationClient) {
		return `Bonjour ${client.firstname},

Voici comme convenu un récapitulatif sur le fonctionnement du service The Good Car.

- <b>Pour l'achat de ta future voiture</b> : nous allons rechercher pour toi la meilleure affaire grâce à notre algorithme, mener toutes les vérifications administratives nécessaires sur l'historique et l'entretien, avant de procéder à la négociation du prix.

Dans notre volonté d'aligner notre intérêt avec celui du client, nous captons une commission de 12% sur la remise que l'on arrive à obtenir par rapport au prix affiché.

Dans le cas où aucune (ou très faible) négociation n'est possible avec le vendeur, du fait par exemple d'un tarif déjà très agressif, le forfait minimum de base est de *montant*€ exceptionnellement ramené pour toi à *montant*€ afin que notre travail de recherches, de vérifications et d'accompagnement soit récompensé.

Nous avons également un partenariat avec un groupement de + de 300 mécaniciens partout en France qui peuvent se déplacer pour réaliser un rapport d'inspection complet sur le véhicule. Le coût supplémentaire de la prestation varie entre 99 et 149€ suivant la distance de déplacement depuis le centre de rattachement.

Une livraison à domicile ou sur le lieu de travail est également possible avec un tarif calculé en fonction de la distance parcourue.

- Si le client décide finalement suite à nos propositions correspondant au cahier des charges d’arrêter de faire appel à nos services en cours de route ou que pour une raison diverse l'échange s’arrête, on lui demande simplement *montant*€ qui couvrent le temps passé et toutes les démarches effectuées pour la recherche du véhicule.

<b><u>Rappel du cahier des charges :</u></b>

*informations supplémentaires*

<b>Pour lancer les opérations il suffit juste de me confirmer par retour de mail "Bon pour accord" que le cahier des charges et le fonctionnement du service te conviennent.</b>

Merci.

A bientôt,
`;
	}

	public getSubject(client: INotificationClient) {
		return `Mail récapitulatif ${client.firstname} ${client.lastname} / The Good Car`;
	}
}

export class FullRecapPoliteNotificationTemplate extends ANotificationTemplate {
	public name = 'Récapitulatif complet (vous)';

	public getContent(client: INotificationClient) {
		return `Bonjour ${client.firstname},

Voici comme convenu un récapitulatif sur le fonctionnement du service The Good Car.

- <b>Pour l'achat de votre future voiture</b> : nous allons rechercher pour vous la meilleure affaire grâce à notre algorithme, mener toutes les vérifications administratives nécessaires sur l'historique et l'entretien, avant de procéder à la négociation du prix.

Dans notre volonté d'aligner notre intérêt avec celui du client, nous captons une commission de 12% sur la remise que l'on arrive à obtenir par rapport au prix affiché.
Dans le cas où aucune (ou très faible) négociation n'est possible avec le vendeur, du fait par exemple d'un tarif déjà très agressif, le forfait minimum de base est de *montant*€ exceptionnellement ramené pour vous à *montant*€ afin que notre travail de recherches, de vérifications et d'accompagnement soit récompensé.

- <b>Concernant l'aide à la revente de *marque voiture*</b> nous négocions une reprise par un professionnel de l'automobile la plus élevée possible et dans la limite de ce qui est réalisable par rapport à vos attentes, réelle solution de facilité et tranquillité pour être sûr de ne pas être embêté.

Nous avons également un partenariat avec un groupement de + de 300 mécaniciens partout en France qui peuvent se déplacer pour réaliser un rapport d'inspection complet sur le véhicule. Le coût supplémentaire de la prestation varie entre 99 et 149€ suivant la distance de déplacement depuis le centre de rattachement.

Une livraison à domicile ou sur le lieu de travail est également possible avec un tarif calculé en fonction de la distance parcourue.

- Si le client décide finalement suite à nos propositions correspondant au cahier des charges d’arrêter de faire appel à nos services en cours de route ou que pour une raison diverse l'échange s’arrête, on lui demande simplement *montant*€ qui couvrent le temps passé et toutes les démarches effectuées pour la recherche du véhicule.

<b><u>Rappel du cahier des charges :</u></b>

*informations supplémentaires*

<b>Pour lancer les opérations, il suffit juste de me confirmer par retour de mail "Bon pour accord" que le cahier des charges et le fonctionnement du service vous conviennent.</b>

Merci.

A bientôt,
`;
	}

	public getSubject(client: INotificationClient) {
		return `Mail récapitulatif ${client.firstname} ${client.lastname} / The Good Car`;
	}
}

export class FullRecapNormalNotificationTemplate extends ANotificationTemplate {
	public name = 'Récapitulatif complet (tu)';

	public getContent(client: INotificationClient) {
		return `Bonjour ${client.firstname},

Voici comme convenu un récapitulatif sur le fonctionnement du service The Good Car.

- <b>Pour l'achat de ta future voiture</b> : nous allons rechercher pour toi la meilleure affaire grâce à notre algorithme, mener toutes les vérifications administratives nécessaires sur l'historique et l'entretien, avant de procéder à la négociation du prix.

Dans notre volonté d'aligner notre intérêt avec celui du client, nous captons une commission de 12% sur la remise que l'on arrive à obtenir par rapport au prix affiché.
Dans le cas où aucune (ou très faible) négociation n'est possible avec le vendeur, du fait par exemple d'un tarif déjà très agressif, le forfait de minimum de base est de *montant*€ exceptionnellement ramené pour toi à *montant*€ afin que notre travail de recherches, de vérifications et d'accompagnement soit récompensé.

- <b>Concernant l'aide à la revente de *marque voiture* actuelle</b> nous négocions une reprise par un professionnel de l'automobile la plus élevée possible et dans la limite de ce qui est réalisable par rapport à tes attentes, réelle solution de facilité et tranquillité pour être sûr de ne pas être embêté.

Nous avons également un partenariat avec un groupement de + de 300 mécaniciens partout en France qui peuvent se déplacer pour réaliser un rapport d'inspection complet sur le véhicule. Le coût supplémentaire de la prestation varie entre 99 et 149€ suivant la distance de déplacement depuis le centre de rattachement.

Une livraison à domicile ou sur le lieu de travail est également possible avec un tarif calculé en fonction de la distance parcourue.

- Si le client décide finalement suite à nos propositions correspondant au cahier des charges d’arrêter de faire appel à nos services en cours de route ou que pour une raison diverse l'échange s’arrête, on lui demande simplement *montant*€ qui couvrent le temps passé et toutes les démarches effectuées pour la recherche du véhicule.

<b><u>Rappel du cahier des charges :</u></b>

*informations supplémentaires*

<b>Pour lancer les opérations il suffit juste de me confirmer par retour de mail "Bon pour accord" que le cahier des charges et le fonctionnement du service te conviennent.</b>

Merci.

A bientôt,
`;
	}

	public getSubject(client: INotificationClient) {
		return `Mail récapitulatif ${client.firstname} ${client.lastname} / The Good Car`;
	}
}
