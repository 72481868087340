import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, startWith, switchMap, tap } from 'rxjs/operators';
import { IDealership } from '../../../../../../core/interfaces';
import { DealershipsService } from '../services/dealerships.service';

@Component({
  selector: 'tgc-dealerships',
  templateUrl: './dealerships.component.html',
  styleUrls: ['./dealerships.component.scss'],
})

export class DealershipsComponent {
  brands$: Observable<string[]>;
  locations$: Observable<{ name: string, number: number }[]>;
  dealerships$: Observable<IDealership[]>;

  form: FormGroup;
  selected: IDealership;
  isLoading: boolean;

  constructor(private service: DealershipsService) {
    this.brands$ = this.service.getBrands();
    this.locations$ = this.service.getLocations();

    this.form = new FormGroup({
      brands: new FormControl(),
      locations: new FormControl(),
      search: new FormControl(),
    });

    this.dealerships$ = this.form.valueChanges.pipe(
      debounceTime(300),
      tap(() => this.isLoading = true),
      switchMap(({ brands, locations, search }) => this.service.getDealerships(brands, locations, search)),
      tap(() => this.isLoading = false),
      tap(dealerships => this.selected = dealerships[0]),
      startWith([]),
    );
  }
}
