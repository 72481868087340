import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { TextFieldModule } from '@angular/cdk/text-field';

import { RouterModule } from '@angular/router';
import { AgGridModule } from 'ag-grid-angular';
import { ScrapperResultsModule } from '../scrapper/scrapper-results/scrapper-results.module';
import { ProposalService } from '../services/proposal.service';
import { AlertsComponent } from './alerts/alerts.component';
import { ClientListComponent } from './client-list/client-list.component';
import { ClientComponent } from './client/client.component';
import { EmailComponent } from './email/email.component';
import { FormsComponent } from './forms/forms.component';
import { HistoryItemComponent } from './history/history-item/history-item.component';
import { HistoryComponent } from './history/history.component';
import { HomeComponent } from './home/home.component';
import { ProposalModule } from './proposal/proposal.module';
import { SafeHtmlPipe } from './safe-html.pipe';

@NgModule({
	imports: [
		AgGridModule.withComponents([]),
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule,
		ScrapperResultsModule,
		MatExpansionModule,
		MatChipsModule,
		MatButtonModule,
		MatButtonToggleModule,
		MatCardModule,
		MatCheckboxModule,
		MatDialogModule,
		MatIconModule,
		MatInputModule,
		MatGridListModule,
		MatSelectModule,
		MatProgressSpinnerModule,
		MatTabsModule,
		ProposalModule,
		MatToolbarModule,
		TextFieldModule,
	],
	declarations: [
		ClientListComponent,
		ClientComponent,
		EmailComponent,
		FormsComponent,
		HomeComponent,
		AlertsComponent,
		SafeHtmlPipe,
		HistoryComponent,
		HistoryItemComponent,
	],
	providers: [ProposalService],
})
export class ClientsModule {}
