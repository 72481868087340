import { Component, OnInit } from '@angular/core';
import {
	Dictionary,
	each,
	flatMap,
	groupBy,
	map,
	without,
} from 'lodash';
import {
	IClient,
	IResult,
	IScrapperSearch,
} from '../../../../../../../core/interfaces';
import {
	IResultAction,
	IResultActionClickEvent,
} from '../../scrapper/scrapper-results/scrapper-results.component';
import { filter, take } from 'rxjs/operators';

import { ClientService } from '../../services/client.service';
import { ResultStates } from '../../../../../../../core/interfaces';
import { Router } from '@angular/router';
import { ScrapperService } from '../../services/scrapper.service';

const selectByTgcAction = {
	name: 'select_by_tgc',
	displayName: 'sélectionner',
	icon: 'thumb_up',
} as IResultAction;

const rejectByTgcAction = {
	name: 'reject_by_tgc',
	displayName: 'rejeter',
	icon: 'thumb_down',
} as IResultAction;

@Component({
	selector: 'app-alerts',
	templateUrl: './alerts.component.html',
	styleUrls: ['./alerts.component.scss'],
})
export class AlertsComponent implements OnInit {
	public client: IClient;
	public alerts: IScrapperSearch[];

	public results: IResult[];
	public resultsMap: Dictionary<IResult[]>;

	public isLoading: boolean;

	public get newResults(): IResult[] {
		return this.resultsMap[ResultStates.NEW] || [];
	}

	public newActions = [selectByTgcAction, rejectByTgcAction];

	public get selectedByTgcResults(): IResult[] {
		return this.resultsMap[ResultStates.SELECTED_BY_TGC] || [];
	}

	public selectedByTgcActions = [rejectByTgcAction];

	public get rejectedByTgcResults(): IResult[] {
		return this.resultsMap[ResultStates.REJECTED_BY_TGC] || [];
	}

	public rejectedByTgcActions = [selectByTgcAction];

	constructor(
		private clientService: ClientService,
		private scrapperService: ScrapperService,
		private router: Router,
	) {
		this.alerts = [];
		this.isLoading = false;

		this.resultsMap = {};
	}

	public ngOnInit(): void {
		this.clientService.client$
			.pipe(filter(c => !!c), take(1))
			.subscribe(async client => {
				this.client = client;

				this.alerts = await this.scrapperService.getAlertsForClient(
					'' + this.client.id,
				);

				this.results = await this.scrapperService.getResultsForClient(
					'' + this.client.id,
				);

				this.updateResultMap();
			});
	}

	public async onActionClicked(
		args: IResultActionClickEvent,
	): Promise<void> {
		switch (args.action) {
			case selectByTgcAction.name:
				return await this.selectResult(args.result);
			case rejectByTgcAction.name:
				return await this.rejectResult(args.result);
			default:
				return;
		}
	}

	public getScrapperName(scrapperSearch: IScrapperSearch): string {
		const filters = JSON.parse(scrapperSearch.filtersAsString);
		const filterName = this.scrapperService.getScrapperSearchName(
			filters,
		);

		return filterName;
	}

	public navigateToScrapper(): void {
		this.router.navigate(['scrapper'], {
			queryParams: {
				clientId: this.client.id,
			},
		});
	}

	public async displayResultOfAlert(
		alert: IScrapperSearch,
		uncheckedOnly: boolean,
	): Promise<void> {
		this.isLoading = true;

		this.results = await this.scrapperService.getResultsOfAlert(
			'' + alert.id,
			uncheckedOnly,
		);

		this.isLoading = false;
	}

	public async executeAlert(alert: IScrapperSearch): Promise<void> {
		this.isLoading = true;

		const results = await this.scrapperService.executeSearch(
			'' + alert.id,
		);
		this.alerts = await this.scrapperService.getAlertsForClient(
			'' + this.client.id,
		);
		this.results = flatMap(results, res => res.results);

		this.isLoading = false;
	}

	public async removeAlert(alert: IScrapperSearch): Promise<void> {
		this.isLoading = true;
		await this.scrapperService.deleteSearch('' + alert.id);

		this.alerts = without(this.alerts, alert);

		this.isLoading = false;
	}

	public async editAlert(alert: IScrapperSearch): Promise<void> {
		this.isLoading = true;
		const results = await this.scrapperService.executeSearch(
			'' + alert.id,
		);

		this.results = flatMap(results, res => res.results);

		this.isLoading = false;
	}

	public async selectResult(result: IResult): Promise<void> {
		await this.updateResultState(
			[result],
			ResultStates.SELECTED_BY_TGC,
		);
	}

	public async rejectResult(result: IResult): Promise<void> {
		await this.updateResultState(
			[result],
			ResultStates.REJECTED_BY_TGC,
		);
	}

	private async updateResultState(
		results: IResult[],
		state: ResultStates,
	): Promise<void> {
		try {
			this.isLoading = true;

			await this.scrapperService.updateState(
				map(results, res => res.id),
				state,
			);

			each(results, res => {
				res.state = state;
			});
			this.updateUnreadCounter();

			this.results = [...this.results];
			this.updateResultMap();
		} catch (e) {
			//
		}

		this.isLoading = false;
	}

	private updateUnreadCounter(): void {
		const clientInList = this.clientService.clients$.value.find(
			c => c.id === this.client.id,
		);

		if (!!clientInList) {
			const unreadResults = this.alerts.reduce(
				(memo, alert) => memo + alert.newResultNumber,
				0,
			);

			clientInList.newResultNumber = unreadResults;
		}
	}

	private updateResultMap(): void {
		this.resultsMap = groupBy(this.results, res => res.state);
	}
}
