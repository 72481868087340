<div class="cards-container">
  <mat-card *ngFor="let reco of recommandations">
    <mat-card-header>
      <mat-card-title>{{ reco.model }}</mat-card-title>
      <mat-card-subtitle>{{ reco.motor || ' --- ' }}</mat-card-subtitle>
    </mat-card-header>

    <div mat-card-image [style.background-image]="'url(' + reco.image + ')'" alt=""></div>

    <mat-card-actions>
      <button mat-button (click)="update(reco)">MODIFIER</button>
      <button mat-button (click)="deleteReco(reco)">SUPPRIMER</button>
    </mat-card-actions>
  </mat-card>
</div>

<div class="add-button-container">
  <button mat-button (click)="create()">AJOUTER</button>
</div>
