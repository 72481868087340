import { AppModule } from './app/app.module';
import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { init } from '@sentry/angular';
import { RAVEN_URL } from './app/config/globals';

if (environment.production) {
	enableProdMode();
	init({ dsn: RAVEN_URL })
}

platformBrowserDynamic().bootstrapModule(AppModule);
