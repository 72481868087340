<h1 mat-dialog-title>{{recommandation?.model || 'Nouveau bon plan'}}</h1>

<div mat-dialog-content [formGroup]="form">
  <div class="line">
    <mat-form-field>
      <mat-label>Modèle</mat-label>
      <input matInput formControlName="model">
    </mat-form-field>
    <mat-form-field>
      <mat-label>Motorisation</mat-label>
      <input matInput formControlName="motor">
    </mat-form-field>
  </div>
  <div class="line">
    <mat-form-field>
      <mat-label>Kilométrage</mat-label>
      <input matInput formControlName="mileage" type="number">
    </mat-form-field>
    <mat-form-field>
      <mat-label>Année</mat-label>
      <input matInput formControlName="year" type="number">
    </mat-form-field>
  </div>
  <div class="line">
    <mat-form-field>
      <mat-label>Prix</mat-label>
      <input matInput formControlName="price" type="number">
    </mat-form-field>
    <mat-form-field>
      <mat-label>Garantie</mat-label>
      <input matInput formControlName="warranty">
    </mat-form-field>
  </div>
  <div class="line">
    <mat-form-field>
      <mat-label>Équipement 1</mat-label>
      <input matInput formControlName="equipment1">
    </mat-form-field>
    <mat-form-field>
      <mat-label>Équipement 2</mat-label>
      <input matInput formControlName="equipment2">
    </mat-form-field>
    <mat-form-field>
      <mat-label>Équipement 3</mat-label>
      <input matInput formControlName="equipment3">
    </mat-form-field>
  </div>

  <h4>Image</h4>

  <div>
    <img *ngIf="image" [src]="image" alt="">
    <button mat-button *ngIf="image; else: imagePicker" (click)="resetImage()">Supprimer l'image</button>
    <ng-template #imagePicker>
      <mat-form-field>
        <mat-label>Depuis un lien</mat-label>
        <input matInput formControlName="imageUrl">
      </mat-form-field>
      <button mat-button (click)="fileInput.click()">
        Depuis un fichier
      </button>
    </ng-template>
    <input #fileInput type="file" style="display: none;" (change)="onImageFileChange()">
  </div>
</div>
<div mat-dialog-actions>
  <mat-chip-list *ngIf="error">
    <mat-chip color="warn" selected="true">{{error}}</mat-chip>
  </mat-chip-list>
  <button mat-raised-button color="primary" [disabled]="loading" (click)="submit()">
    {{recommandation ? 'Modifier' : 'Créer' }}
  </button>
  <button mat-button (click)="close()">Annuler</button>
</div>
