import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
	IResult,
	IScrapperSearch,
	IWebsiteResult, ResultStates
} from '../../../../../../core/interfaces';
import {
	IFilter,
	IScrapperSearchCreationParams
} from '../../../../../../core/scrapper/interfaces';
import { generateScrapperName } from '../scrapper/helpers/scrapper-name-builder';
import { AuthService } from './auth.service';


@Injectable()
export class ScrapperService {
	constructor(
		private authService: AuthService,
		private httpClient: HttpClient,
	) {}

	public async getAlertsForClient(
		clientId: string,
	): Promise<IScrapperSearch[]> {
		const searchResult = await this.httpClient
			.get<IScrapperSearch[]>(
				`/api/scrapper-search/client/${clientId}`,
				this.authService.authorizedHeader,
			)
			.toPromise();

		return searchResult;
	}

	public async getAlertById(alertId: string): Promise<IScrapperSearch> {
		const searchResult = await this.httpClient
			.get<IScrapperSearch>(
				`/api/scrapper-search/find/${alertId}`,
				this.authService.authorizedHeader,
			)
			.toPromise();

		return searchResult;
	}

	public async getResultsForClient(clientId: string): Promise<IResult[]> {
		const searchResult = await this.httpClient
			.get<IResult[]>(
				`/api/results/client/${clientId}`,
				this.authService.authorizedHeader,
			)
			.toPromise();

		return searchResult;
	}

	public async getResultsOfAlert(
		searchId: string,
		uncheckedOnly: boolean,
	): Promise<IResult[]> {
		const searchResult = await this.httpClient
			.get<IResult[]>(
				`/api/results/scrapper-search/${searchId}?uncheckedOnly=${uncheckedOnly}`,
				this.authService.authorizedHeader,
			)
			.toPromise();

		return searchResult;
	}

	public async search(filter: IFilter): Promise<IWebsiteResult[]> {
		const searchResult = await this.httpClient
			.post<IScrapperSearch>(
				`/api/scrapper-search/search`,
				filter,
				this.authService.authorizedHeader,
			)
			.toPromise();

		return searchResult.websiteResults;
	}

	public async executeSearch(searchId: string): Promise<IWebsiteResult[]> {
		const searchResult = await this.httpClient
			.post<IScrapperSearch>(
				`/api/scrapper-search/execute/${searchId}`,
				null,
				this.authService.authorizedHeader,
			)
			.toPromise();

		return searchResult.websiteResults;
	}

	public async updateState(
		resultIds: number[],
		newState: ResultStates,
	): Promise<void> {
		await this.httpClient
			.put(
				`/api/results/updateState`,
				{ resultIds, newState },
				this.authService.authorizedHeader,
			)
			.toPromise();
	}

	public async createSearch(
		filter: IScrapperSearchCreationParams,
	): Promise<IScrapperSearch> {
		const searchResult = await this.httpClient
			.post<IScrapperSearch>(
				`/api/scrapper-search`,
				filter,
				this.authService.authorizedHeader,
			)
			.toPromise();

		return searchResult;
	}

	public async updateSearch(
		searchId: string,
		filter: IFilter,
	): Promise<IScrapperSearch> {
		const searchResult = await this.httpClient
			.put<IScrapperSearch>(
				`/api/scrapper-search/${searchId}`,
				filter,
				this.authService.authorizedHeader,
			)
			.toPromise();

		return searchResult;
	}

	public async deleteSearch(searchId: string): Promise<void> {
		const searchResult = await this.httpClient
			.delete<void>(
				`/api/scrapper-search/${searchId}`,
				this.authService.authorizedHeader,
			)
			.toPromise();

		return searchResult;
	}

	public searchBrand(search: string): Observable<string[]> {
		return this.httpClient
			.get<string[]>(
				`/api/scrapper/brands?q=${search}`,
				this.authService.authorizedHeader,
			);
	}

	public searchModel(brand: string, search: string): Observable<string[]> {
		return this.httpClient
			.get<string[]>(
				`/api/scrapper/models?brand=${brand}&q=${search}`,
				this.authService.authorizedHeader,
			);
	}

	public getVersionTagCloud(
		brand: string,
		model: string,
	): Observable<string[]> {
		return this.httpClient.get<string[]>(
			`/api/scrapper/versionsTagCloud?brand=${encodeURIComponent(
				brand,
			)}&model=${encodeURIComponent(model)}`,
			this.authService.authorizedHeader,
		);
	}

	public getScrapperSearchName(filters: IFilter): string {
		return generateScrapperName(filters);
	}
}
