import { Component, TrackByFunction, forwardRef } from '@angular/core';
import {
	AsyncValidator,
	ControlValueAccessor,
	FormControl,
	NG_ASYNC_VALIDATORS,
	NG_VALUE_ACCESSOR,
	ValidationErrors
} from '@angular/forms';
import { each, filter, last, partition, toArray, without } from 'lodash';

import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable, map, merge, reduce } from 'rxjs';
import { environment } from '../../../../../environments/environment';

export interface IFilesToUpload {
	id?: number;
	file: File;
	url: string;
	isMain: boolean;
	name: string;
}

function humanSize(size: number): { unit: string; value: number } {
	if (size < 1_000_000) {
		return { unit: 'Ko', value: Math.round(size / 1_000) };
	} else {
		return { unit: 'Mo', value: Math.round(size / 1_000_000) };
	}
}

@Component({
	selector: 'app-proposal-image-picker',
	templateUrl: './proposal-image-picker.component.html',
	styleUrls: ['./proposal-image-picker.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => ProposalImagePickerComponent),
			multi: true,
		},
		{
			provide: NG_ASYNC_VALIDATORS,
			useExisting: forwardRef(() => ProposalImagePickerComponent),
			multi: true,
		},
	],
})
export class ProposalImagePickerComponent
	implements ControlValueAccessor, AsyncValidator {
	public files: IFilesToUpload[];

	public isDisabled: boolean;
	public displayUrlInput: boolean;
	public imageUrl: FormControl;

	public trackImageBy: TrackByFunction<IFilesToUpload> = (_, file) => file.url;

	private _onChange: (files: IFilesToUpload[]) => void;

	constructor(
		public sanitizer: DomSanitizer,
		private httpClient: HttpClient,
	) {
		this.files = [];
		this.displayUrlInput = false;

		this.imageUrl = new FormControl('');
	}

	public validate(): Observable<ValidationErrors> {
		const [locals, remotes] = partition(this.files, f => f.file);
		const localSize = locals.reduce((acc, f) => acc + f.file.size, 0);

		const remoteSize$ = merge(
			...remotes.map(
				f => this.httpClient.head(
					environment.pictureBaseUrl + new URL(f.url).pathname,
					{ observe: 'response', params: { v: 1 } }, // Bypass cache
				),
			),
			3,
		).pipe(
			map(res => res.headers.get('content-length')),
			map(size => parseInt(size, 10)),
			reduce((acc, size) => acc + size, 0),
		);

		return remoteSize$.pipe(
			map(remoteSize => localSize + remoteSize),
			map(size => size > 4_500_000 ? { tooLarge: { max: humanSize(4_500_000), current: humanSize(size) } } : null),
		);
	}

	public onFileSelected($event: any): void {
		let files = toArray<File>($event.srcElement.files);
		$event.srcElement.value = '';

		files = filter(files, f => this.isValidFile(f));

		const newFiles = files.map(
			file =>
				({
					file,
					url: URL.createObjectURL(file),
				} as IFilesToUpload),
		);

		this.files = [...this.files, ...newFiles];

		this._onChange(this.files);
	}

	public selectPictures(file: IFilesToUpload): void {
		each(this.files, f => {
			f.isMain = f === file;
		});

		this._onChange(this.files);
	}

	public removePicture(file: IFilesToUpload): void {
		this.files = without(this.files, file);

		this._onChange(this.files);
	}

	public writeValue(files: IFilesToUpload[]): void {
		this.files = files;
	}

	public registerOnChange(fn: any): void {
		this._onChange = fn;
	}

	public registerOnTouched(): void {
		// Not implemented
	}

	public setDisabledState?(isDisabled: boolean): void {
		this.isDisabled = isDisabled;
	}

	public async addImageFromUrl(url: string): Promise<void> {
		const name = last((url || '').split('/')) || 'unkwown';

		this.imageUrl.disable();

		if (!await this.isValidUrl(url)) {
			this.imageUrl.setValue('');
			this.imageUrl.enable();
			return;
		}

		await this.loadImage(url);

		this.files = [
			...this.files,
			{
				file: null,
				url,
				name,
				isMain: false,
			},
		];

		this.imageUrl.setValue('');
		this.imageUrl.enable();

		this._onChange(this.files);
	}

	private isValidFile(file: File) {
		return file.type.split('/')[0] === 'image';
	}
	private async isValidUrl(url: string): Promise<boolean> {
		const res = await this.httpClient
			.get<{ isValid: boolean }>(
				`/api/proposals/testPictureUrl?url=${url}`,
			)
			.toPromise();

		return res.isValid;
	}

	private loadImage(url: string): Promise<void> {
		return new Promise<void>(resolve => {
			const img = new Image();
			img.src = url;
			img.onload = () => resolve();
		});
	}
}
