import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ScrapperResultsComponent } from './scrapper-results.component';
import { OverlayModule } from '@angular/cdk/overlay';

@NgModule({
	imports: [
		CommonModule,
		MatListModule,
		MatTooltipModule,
		MatIconModule,
		OverlayModule,
	],
	declarations: [ScrapperResultsComponent],
	exports: [ScrapperResultsComponent],
})
export class ScrapperResultsModule {}
