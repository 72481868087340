import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { find } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { IClient } from '../../../../../../core/interfaces';
import {
	IEmail,
	ISentSMS
} from '../../../../../../core/notification/interfaces';
import { AuthService } from './auth.service';

@Injectable()
export class ClientService {
	public legalEntityId$: BehaviorSubject<number>;
	public client$: BehaviorSubject<IClient>;
	public clients$: BehaviorSubject<IClient[]>;

	public isLoading$: BehaviorSubject<boolean>;

	private clientCache: { [key: string]: IClient };

	constructor(
		private authService: AuthService,
		private http: HttpClient,
	) {
		this.clients$ = new BehaviorSubject([]);
		this.client$ = new BehaviorSubject(null);
		this.legalEntityId$ = new BehaviorSubject(null);

		this.isLoading$ = new BehaviorSubject(false);

		this.clientCache = {};

		this.authService.principal$.pipe(
			take(1),
		).subscribe(principal => this.init(principal.legalEntity.id))
	}

	public setClient(client: IClient): void {
		this.client$.next(client);
	}

	public async getAll(
		filteredLegalEntityId?: number,
	): Promise<IClient[]> {
		this.isLoading$.next(false);

		const result = await this.http
			.get<IClient[]>(`/api/clients`, {
				...this.authService.authorizedHeader,
				params:
					(filteredLegalEntityId && {
						legalEntityId: filteredLegalEntityId.toString(),
					}) ||
					{},
			})
			.toPromise();

		this.isLoading$.next(false);

		return result;
	}

	public async getOne(
		clientId: string,
		refresh: boolean = false,
	): Promise<IClient> {
		if (refresh || !this.clientCache[clientId]) {
			this.isLoading$.next(true);

			const client = await this.http
				.get<IClient>(
					`/api/clients/find/${clientId}`,
					this.authService.authorizedHeader,
				)
				.toPromise();

			this.isLoading$.next(false);

			const cachedClient = this.clientCache[clientId];

			if (cachedClient && refresh) {
				Object.assign(cachedClient, client);
			} else {
				this.clientCache[clientId] = client;
			}
		}

		return this.clientCache[clientId];
	}

	public async updateClient(
		clientId: string,
		client: { [P in keyof IClient]?: IClient[P] },
	): Promise<IClient> {
		this.isLoading$.next(true);

		const newClient = await this.http
			.put<IClient>(
				`/api/clients/${clientId}`,
				client,
				this.authService.authorizedHeader,
			)
			.toPromise();

		this.isLoading$.next(false);

		this.clientCache[clientId] = newClient;

		const clients = this.clients$.value;
		const cachedClient = find(clients, c => '' + c.id === clientId);

		if (!!cachedClient) {
			Object.assign(cachedClient, newClient);
			this.clients$.next(clients);
		}

		return newClient;
	}

	public async sendEmail(
		clientId: number,
		email: IEmail,
	): Promise<void> {
		this.isLoading$.next(true);

		await this.http
			.post(
				`/api/clients/${clientId}/emails`,
				email,
				this.authService.authorizedHeader,
			)
			.toPromise();

		await this.getOne('' + clientId, true);

		this.isLoading$.next(false);
	}

	public async getSmsById(id: string): Promise<ISentSMS> {
		this.isLoading$.next(true);

		const result = this.http
			.get<ISentSMS>(
				`/api/sms/${id}`,
				this.authService.authorizedHeader,
			)
			.toPromise();

		this.isLoading$.next(false);

		return result;
	}

	public async init(filteredLegalEntityId?: number): Promise<void> {
		this.isLoading$.next(true);

		this.legalEntityId$.next(filteredLegalEntityId);

		const clients = await this.getAll(filteredLegalEntityId);

		this.clients$.next(clients);

		this.isLoading$.next(false);
	}
}
