import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable, defer, EMPTY } from 'rxjs';
import { catchError, shareReplay } from 'rxjs/operators';
import { setContext } from '@sentry/angular';
import {
	ADMIN_GROUP_NAME,
	USER_GROUP_NAME
} from '../../../../../../core/globals';
import {
	IAuthenticatedUser,
	IPrincipal
} from '../../../../../../core/users/interfaces';
import { isUserWithUserGroup } from '../../../../../../core/users/utils';

// Avoid name not found warnings
declare const Auth0Lock: any;

@Injectable()
export class AuthService {
	public user: IAuthenticatedUser;
	public principal$: Observable<IPrincipal>;

	private tokenId: string;
	private jwtHelperService: JwtHelperService;

	public lock = !!Auth0Lock
		? new Auth0Lock(
				'NeTCgBh1LOD43DE6fkp3EoY6c55vQdGv',
				'ebrithil.eu.auth0.com',
				{
					language: 'fr',
					auth: {
						params: {
							scope: 'openid name groups email user_metadata',
						},
						responseType: 'token',
						redirectUrl: !!window ? `${window.location.origin}` : '',
					},
				},
			)
		: null;

	constructor(private httpClient: HttpClient) {
		this.jwtHelperService = new JwtHelperService();

		if (this.authenticated) {
			const lastLoggedUser = localStorage.getItem('user');
			const lastTokenId = localStorage.getItem('id_token');

			if (!!lastLoggedUser) {
				this.user = JSON.parse(lastLoggedUser);
			}
			if (!!lastTokenId) {
				this.tokenId = lastTokenId;
			}
		} else {
			this.clearStorage();
		}

		this.principal$ = this.getPrincipal();
	}

	public login(): void {
		// Call the show method to display the widget.
		this.lock.show();
	}

	public authenticate(): Promise<void> {
		console.log('authenticate');

		return new Promise((resolve, reject) => {
			this.lock.resumeAuth(
				window.location.hash,
				(error, authResult) => {
					if (error) {
						console.log(error);

						return reject();
					}

					this.lock.getUserInfo(
						authResult.accessToken,
						(err, profile) => {
							if (!err) {
								this.setUser(authResult, profile);

								const lastUrl = this.getInLocalStorage(
									'params_before_login',
									'',
								);

								if (lastUrl) {
									window.location.href = lastUrl;
								}

								return resolve();
							}

							reject();
						},
					);
				},
			);
		});
	}

	public get authenticated(): boolean {
		try {
			const token = this.getInLocalStorage('id_token', undefined);
			return token && !this.jwtHelperService.isTokenExpired(token);
		} catch (e) {
			return false;
		}
	}

	public get isCarAdvisor(): boolean {
		return (
			this.authenticated &&
			isUserWithUserGroup(this.user, USER_GROUP_NAME)
		);
	}

	public get isAdmin(): boolean {
		return (
			this.authenticated &&
			isUserWithUserGroup(this.user, ADMIN_GROUP_NAME)
		);
	}

	public logout(): void {
		this.clearStorage();
		this.user = null;
		this.tokenId = null;
	}

	private getPrincipal(): Observable<IPrincipal> {
		return defer(() => this.httpClient.get<IPrincipal>('/api/auth/principal', this.authorizedHeader))
			.pipe(
				catchError(() => EMPTY),
				shareReplay(1),
			);
	}

	public get authorizedHeader(): { headers: HttpHeaders } {
		let headers = new HttpHeaders({
			Authorization: `Bearer ${this.tokenId}`,
		});

		try {
			const impersonationId = localStorage.getItem(
				'X-Impersonation-Id',
			);
			if (impersonationId) {
				headers = headers.append(
					'X-Impersonation-Id',
					impersonationId,
				);
			}
		} catch (e) {}

		return {
			headers,
		};
	}

	private setUser(authResult, profile): void {
		this.user = profile;
		this.tokenId = authResult.idToken;

		setContext('user', this.user);

		try {
			localStorage.setItem('access_token', authResult.accessToken);
			localStorage.setItem('id_token', authResult.idToken);
			localStorage.setItem('user', JSON.stringify(profile));
		} catch (e) {
			console.warn('Error while using localStorage');
		}
	}

	private clearStorage(): void {
		try {
			localStorage.removeItem('access_token');
			localStorage.removeItem('id_token');
			localStorage.removeItem('user');
		} catch (e) {
			console.warn('Error while using localStorage');
		}
	}

	private getInLocalStorage(
		key: string,
		valueIfNotPresent: string,
	): string {
		try {
			return localStorage.getItem(key) || valueIfNotPresent;
		} catch (error) {
			return valueIfNotPresent;
		}
	}
}
