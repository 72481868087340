import { Component, OnInit } from '@angular/core';
import {
	IClient,
	IProposal,
} from '../../../../../../../../core/interfaces';
import { filter, take } from 'rxjs/operators';

import { ClientService } from '../../../services/client.service';
import { MatDialog } from '@angular/material/dialog';
import { ProposalDialogComponent } from '../proposal-dialog/proposal-dialog.component';
import { ProposalService } from '../../../services/proposal.service';
import { ProposalStates } from '../../../../../../../../core/interfaces';

interface IChip {
	name: string;
	color: string;
}

@Component({
	selector: 'app-proposal-list',
	templateUrl: './proposal-list.component.html',
	styleUrls: ['./proposal-list.component.scss'],
})
export class ProposalListComponent implements OnInit {
	public client: IClient;

	public proposals: IProposal[];

	public displayedProposal: IProposal;
	public selectedProposal: IProposal;
	public chipMap: { [state: string]: IChip[] };

	public constructor(
		private proposalService: ProposalService,
		private clientService: ClientService,
		private dialog: MatDialog,
	) {
		this.proposals = [];

		this.clientService.client$.subscribe(
			client => (this.client = client),
		);

		this.chipMap = [
			ProposalStates.NEW,
			ProposalStates.EXPIRED,
			ProposalStates.REJECTED,
			ProposalStates.SELECTED,
			ProposalStates.WAITING,
		].reduce((acc, state) => {
			acc[state] = this.getChips(state);

			return acc;
		}, {});
	}

	public async ngOnInit(): Promise<void> {
		this._init();
	}

	public display(proposal: IProposal): void {
		this.dialog.open(ProposalDialogComponent, {
			data: { proposal },
			panelClass: 'proposal-dialog-panel',
			maxWidth: '95vw',
		});
	}

	public update(proposal: IProposal): void {
		this.selectedProposal = proposal;
	}

	public create(): void {
		this.selectedProposal = {} as IProposal;
	}

	public async delete(proposal: IProposal): Promise<void> {
		const hasConfirmed = confirm(
			`Êtes-vous sûr de bien vouloir supprimer la proposition ${proposal.name} ?`,
		);

		if (!hasConfirmed) {
			return;
		}

		await this.proposalService.remove(proposal);
		await this._init();

		this.selectedProposal = null;
	}

	public async onSave(formValue: any): Promise<void> {
		const { pictures } = formValue;

		await this.proposalService.save(formValue, pictures);

		await this._init();
		this.selectedProposal = null;
	}

	public getChips(state: ProposalStates): IChip[] {
		switch (state) {
			case ProposalStates.NEW:
				return [
					{
						name: 'Pas ouvert',
						color: 'new',
					},
				];
			case ProposalStates.SELECTED:
				return [
					{
						name: 'Approuvé',
						color: 'selected',
					},
				];
			case ProposalStates.REJECTED:
				return [
					{
						name: 'Refusé',
						color: 'rejected',
					},
				];
			case ProposalStates.EXPIRED:
				return [
					{
						name: 'Expiré',
						color: 'grey',
					},
				];
			case ProposalStates.WAITING:
				return [
					{
						name: 'En attente',
						color: 'waiting',
					},
				];
			default:
				return [];
		}
	}

	private async _init(): Promise<void> {
		this.clientService.client$
			.pipe(filter(c => !!c), take(1))
			.subscribe(async client => {
				this.proposals = await this.proposalService.getProposalsForClient(
					client.id + '',
				);
			});
	}
}
