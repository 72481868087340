import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { ILegalEntity } from '../../../../../../core/interfaces';
import { AuthService } from './auth.service';

@Injectable()
export class LegalEntitiesService {
	all$: Observable<ILegalEntity[]>;

	constructor(
		private httpClient: HttpClient,
		private authService: AuthService,
	) {
		this.all$ = this.getAll();
	}

	public getAll(): Observable<ILegalEntity[]> {
		return this.httpClient
			.get<ILegalEntity[]>(
				'/api/legalEntities',
				this.authService.authorizedHeader,
			)
			.pipe(shareReplay(1));
	}
}
