<div class="copy-paste">
	<button mat-raised-button (click)="copy()" *ngIf="canCopy">Copier cette offre</button>
	<button mat-raised-button (click)="paste()" *ngIf="canPaste">Coller la proposition {{ copiedProposal.name }}</button>
</div>

<form [formGroup]="form" novalidate>
	<mat-form-field class="line-100">
		<input matInput formControlName="name" placeholder="Nom">
	</mat-form-field>
	<mat-form-field class="line-33">
		<input type="number" matInput formControlName="price" placeholder="Prix">
	</mat-form-field>
	<mat-form-field class="line-33">
		<input matInput formControlName="year" placeholder="Année">
	</mat-form-field>
	<mat-form-field class="line-33">
		<input type="number" matInput formControlName="kilometers" placeholder="Kilomètrage">
	</mat-form-field>
	<mat-form-field class="line-33">
		<input matInput formControlName="energy" placeholder="Énergie">
	</mat-form-field>
	<mat-form-field class="line-33">
		<input matInput formControlName="gearbox" placeholder="Boîte de vitesses">
	</mat-form-field>
	<mat-form-field class="line-33">
		<input matInput formControlName="waranty" placeholder="Garantie">
	</mat-form-field>
	<mat-form-field class="line-100">
		<textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="5" formControlName="equipments" placeholder="Équipement"></textarea>
	</mat-form-field>
	<mat-form-field class="line-100">
		<textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="5" formControlName="additionalInformation" placeholder="Plus d'informations"></textarea>
	</mat-form-field>
	<app-proposal-image-picker formControlName="pictures"></app-proposal-image-picker>

	<div *ngIf="form.get('pictures').errors?.tooLarge as error" style="color: red; margin-bottom: 1rem;">
		La taille des images ne doit pas dépasser {{ error.max.value }}{{ error.max.unit }}. Taille actuelle : {{ error.current.value }}{{ error.current.unit }}.
	</div>
</form>
<button mat-raised-button color="primary" (click)="save()" [disabled]="!form.valid">Sauvegarder</button>
<button mat-raised-button (click)="preview()">Afficher l'aperçu</button>
<button mat-raised-button (click)="cancel()">Annuler</button>
