import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';

import { CommonModule } from '@angular/common';
import { GoogleService } from '../services/google.service';
import { NgModule } from '@angular/core';
import { PlaceSelectComponent } from './place-select/place-select.component';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ScrapperComponent } from './scrapper.component';
import { ScrapperService } from '../services/scrapper.service';
import { VersionSelectComponent } from './version-select/version-select.component';
import { WebsiteScrapperResultsModule } from './website-scrapper-results/website-scrapper-results.module';

@NgModule({
	imports: [
		CommonModule,
		MatAutocompleteModule,
		MatButtonModule,
		MatChipsModule,
		MatInputModule,
		MatFormFieldModule,
		MatListModule,
		MatProgressBarModule,
		MatSelectModule,
		MatTooltipModule,
		ReactiveFormsModule,
		RouterModule,
		WebsiteScrapperResultsModule,
	],
	declarations: [
		ScrapperComponent,
		PlaceSelectComponent,
		VersionSelectComponent,
	],
	exports: [ScrapperComponent, PlaceSelectComponent],
	providers: [GoogleService, ScrapperService],
})
export class ScrapperModule {}
