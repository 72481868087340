<div class="container">
	<mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
	<div *ngIf="!!client">
		<a [routerLink]="['/clients', client.id, 'alerts']">Retour vers la fiche de {{ client.firstname }} {{ client.lastname }}</a>
	</div>
	<h4 [style.opacity]="!!searchName ? 1 : 0">Recherche : {{ searchName }}</h4>
	<form [formGroup]="form" novalidate (keydown.enter)="$event.preventDefault()">
		<table formGroupName="position">
			<tr>
				<td>
					<app-place-select formControlName="coordinates"></app-place-select>
				</td>
				<td>
					<mat-form-field>
						<input matInput formControlName="radius" placeholder="Rayon (en km)">
					</mat-form-field>
				</td>
			</tr>
		</table>
		<table>
			<tr>
				<td>
					<mat-form-field>
						<input matInput type="number" formControlName="minPrice" placeholder="Prix minimum">
					</mat-form-field>
				</td>
				<td>
					<mat-form-field>
						<input matInput type="number" formControlName="maxPrice" placeholder="Prix maximum">
					</mat-form-field>
				</td>
			</tr>
		</table>
		<table>
			<tr>
				<td>
					<mat-form-field>
						<input matInput type="number" formControlName="minYear" placeholder="Année minimale">
					</mat-form-field>
				</td>
				<td>
					<mat-form-field>
						<input matInput type="number" formControlName="maxYear" placeholder="Année maximale">
					</mat-form-field>
				</td>
			</tr>
		</table>
		<table formGroupName="brand">
			<tr>
				<td>
					<mat-form-field>
						<input matInput formControlName="brand" [matAutocomplete]="brandAuto" placeholder="Marque">
					</mat-form-field>
					<mat-autocomplete #brandAuto="matAutocomplete">
						<mat-option *ngFor="let option of brandOptions$ | async" [value]="option">
							{{ option }}
						</mat-option>
					</mat-autocomplete>
				</td>
				<td>
					<mat-form-field>
						<input matInput formControlName="model" [matAutocomplete]="modelAuto" placeholder="Modèle">
					</mat-form-field>
					<mat-autocomplete #modelAuto="matAutocomplete">
						<mat-option *ngFor="let option of modelOptions$ | async" [value]="option">
							{{ option }}
						</mat-option>
					</mat-autocomplete>
				</td>
			</tr>
		</table>
		<table>
			<tr>
				<td>
					<mat-form-field>
						<input matInput type="number" formControlName="minKilometers" placeholder="Kilométrage min">
					</mat-form-field>
				</td>
				<td>
					<mat-form-field>
						<input matInput type="number" formControlName="maxKilometers" placeholder="Kilométrage max">
					</mat-form-field>
				</td>
			</tr>
		</table>
		<table>
			<tr>
				<td class="gearbox">
					<mat-form-field>
						<mat-select placeholder="Boîte de vitesse" formControlName="gearbox">
							<mat-option *ngFor="let gearbox of gearboxOptions" [value]="gearbox.value">
								{{ gearbox.text }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</td>
				<td class="energy">
					<mat-form-field>
						<mat-select placeholder="Énergie" formControlName="energy">
							<mat-option *ngFor="let energy of energyOptions" [value]="energy.value">
								{{ energy.text }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</td>
				<td class="color">
					<mat-form-field>
						<mat-select placeholder="Couleur" formControlName="color">
							<mat-option *ngFor="let color of colorOptions" [value]="color.value">
								{{ color.text }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</td>
			</tr>
		</table>
		<table>
			<tr>
				<td>
					<app-version-select [brand]="form.get('brand').get('brand').value" [model]="form.get('brand').get('model').value" formControlName="version"></app-version-select>
				</td>
				<td>
					<mat-form-field>
						<input matInput type="text" formControlName="exclusions" placeholder="Exclusions (séparées par des virgules si plusieurs)">
					</mat-form-field>
				</td>
			</tr>
		</table>
		<div class="action">
			<ng-container *ngIf="!client; else scrapperForClient">
				<button mat-raised-button (click)="submit()" [disabled]="isLoading">Rechercher</button>
			</ng-container>
			<ng-template #scrapperForClient>
				<button mat-raised-button (click)="createSearch()" [disabled]="isLoading">Créer une {{ searchId ? 'nouvelle ' : '' }}alerte pour {{ client.firstname }} {{ client.lastname }}</button>
				<button mat-raised-button (click)="updateSearch()" [disabled]="isLoading" *ngIf="searchId && hasFormChanged">Modifier cette alerte</button>
				<button mat-raised-button (click)="resetSearch()" [disabled]="isLoading" *ngIf="searchId && hasFormChanged">Annuler les modifications</button>
				<button mat-raised-button (click)="submit()" [disabled]="isLoading">Tester</button>
			</ng-template>
		</div>
	</form>
	<app-website-scrapper-results [results]="results"></app-website-scrapper-results>
</div>
