<div class="alerts" *ngIf="client">
	<h3>Alertes</h3>
	<div *ngIf="!alerts.length; else alertTable">Aucune alerte</div>
	<button mat-raised-button (click)="navigateToScrapper()">Ajouter une alerte</button>
	<ng-template #alertTable>
		<table>
			<thead>
				<tr>
					<th>Nom</th>
					<th>Résultat(s)</th>
					<th>Nouveau(x)</th>
					<th>Actions</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let alert of alerts">
					<td>{{ getScrapperName(alert) }}</td>
					<td>
						{{ alert.allResultNumber }}
					</td>
					<td>
						{{ alert.newResultNumber }}
					</td>
					<td>
						<button mat-button (click)="executeAlert(alert)" [disabled]="isLoading" title="Exécuter la recherche">
							<i class="material-icons">play_arrow</i>
						</button>
						<button mat-button [routerLink]="['/scrapper', alert.id]" [disabled]="isLoading" title="Éditer la recherche">
							<i class="material-icons">mode_edit</i>
						</button>
						<button mat-button (click)="removeAlert(alert)" [disabled]="isLoading" title="Supprimer la recherche">
							<i class="material-icons">delete</i>
						</button>
					</td>
				</tr>
			</tbody>
		</table>
	</ng-template>
</div>
<div *ngIf="!!alerts.length">
	<h3>Résultats</h3>
	<mat-accordion>
		<mat-expansion-panel [disabled]="!newResults.length">
			<mat-expansion-panel-header>
				<mat-panel-title>
					Nouveau ({{ newResults.length }})
				</mat-panel-title>
				<mat-panel-description>
					Liste des nouveaux résultats
				</mat-panel-description>
			</mat-expansion-panel-header>
			<app-scrapper-results [results]="newResults" (actionClick)="onActionClicked($event)" [actions]="newActions"></app-scrapper-results>
		</mat-expansion-panel>
		<mat-expansion-panel [disabled]="!selectedByTgcResults.length">
			<mat-expansion-panel-header>
				<mat-panel-title>
					Sélectionné ({{ selectedByTgcResults.length }})
				</mat-panel-title>
				<mat-panel-description>
					Liste des résultats sélectionnés
				</mat-panel-description>
			</mat-expansion-panel-header>
			<app-scrapper-results [results]="selectedByTgcResults" (actionClick)="onActionClicked($event)" [actions]="selectedByTgcActions"></app-scrapper-results>
		</mat-expansion-panel>
		<mat-expansion-panel [disabled]="!rejectedByTgcResults.length">
			<mat-expansion-panel-header>
				<mat-panel-title>
					Rejeté ({{ rejectedByTgcResults.length }})
				</mat-panel-title>
				<mat-panel-description>
					Liste des résultats rejetés
				</mat-panel-description>
			</mat-expansion-panel-header>
			<app-scrapper-results [results]="rejectedByTgcResults" (actionClick)="onActionClicked($event)" [actions]="rejectedByTgcActions"></app-scrapper-results>
		</mat-expansion-panel>
	</mat-accordion>
</div>