<div class="grid-container">
	<div class="actions">
		<span>Afficher les clients : </span>
		<mat-button-toggle-group [value]="displayedState$.value">
			<mat-button-toggle
				*ngFor="let state of states"
				[value]="state.value"
				(click)="updateSelectedState(state.value)"
			>
				{{ state.text }}
				<span *ngIf="state.isNewState" class="badge">{{
					newClientsNumber$ | async
				}}</span>
			</mat-button-toggle>
		</mat-button-toggle-group>

		<span class="super-admin-filters" *ngIf="isSuperAdmin$ | async">
			de
			<mat-form-field floatLabel="never">
				<mat-select
					[formControl]="filteredLegalEntityIdFC"
					placeholder="Toutes les franchises"
				>
					<mat-option [value]="null">Toutes les franchises</mat-option>
					<mat-option
						*ngFor="let legalEntity of legalEntities$ | async"
						[value]="legalEntity.id"
					>
						{{ legalEntity.name }}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</span>
	</div>
	<ag-grid-angular
		#agGrid
		class="ag-theme-fresh"
		[gridOptions]="gridOptions"
		[defaultColDef]="defaultColDef"
		(gridReady)="onGridLoaded()"
	>
	</ag-grid-angular>
</div>
