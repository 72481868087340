import { AuthService } from "./admin/services/auth.service";

export function appInitializer(authService: AuthService): () => Promise<void> {
  return async () => {
    if (authService.authenticated) {
      return;
    }

    if (/access_token|id_token|error/.test(window.location.href)) {
      await authService.authenticate();
    }
  };
}
