import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';
import {
	IClient,
	ILegalEntity,
	IScrapperSearch,
	UserScope,
} from '../../../../../../../core/interfaces';
import { AuthService } from '../../services/auth.service';
import { ClientService } from '../../services/client.service';
import { LegalEntitiesService } from '../../services/legal-entity.service';
import {
	IValueText,
	STATE_VALUE_TEXTS,
} from '../client-list/client-list.component';

export enum ClientTabs {
	Forms,
	Alerts,
	Proposals,
	Emails,
	History,
}

@Component({
	selector: 'app-client',
	templateUrl: './client.component.html',
	styleUrls: ['./client.component.scss'],
})
export class ClientComponent implements OnInit, OnDestroy {
	public displayAdmin: boolean;
	public client: IClient;
	public alerts: IScrapperSearch[];

	public isLoading: boolean;
	public states: IValueText<string>[];

	public selectedTabIndex: number;
	public legalEntities$: Observable<ILegalEntity[]>;
	public isSuperAdmin$: Observable<boolean>;

	constructor(
		private clientService: ClientService,
		private route: ActivatedRoute,
		private authService: AuthService,
		private legalEntitiesService: LegalEntitiesService,
	) {
		this.alerts = [];
		this.isLoading = false;

		this.states = STATE_VALUE_TEXTS;
		this.selectedTabIndex = ClientTabs.Forms;

		this.displayAdmin = false;
	}

	public ngOnInit(): void {
		const idKey = 'id';

		this.route.params.subscribe(async params => {
			if (!!params) {
				const clientId = params[idKey];

				this.isLoading = true;

				this.client = await this.clientService.getOne(clientId);
				this.clientService.setClient(this.client);

				this.isLoading = false;
			}
		});

		this.isSuperAdmin$ = this.authService.principal$.pipe(
			map(principal => principal.scope === UserScope.AllLegalEntities),
		);

		this.legalEntities$ = this.legalEntitiesService.all$;
	}

	public ngOnDestroy(): void {
		this.clientService.setClient(null);
	}

	public setLegalEntity(legalEntityId: number): void {
		this.legalEntities$
			.pipe(
				map(legalEntities =>
					legalEntities.find(le => le.id === legalEntityId),
				),
				concatMap(legalEntity =>
					this.clientService.updateClient(this.client.id.toString(), {
						legalEntity,
						owner: legalEntity.owner,
					}),
				),
			)
			.subscribe(client => {
				this.client.legalEntity = client.legalEntity;
				this.client.owner = client.owner;
			});
	}
}
