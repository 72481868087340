import type { ConnectedPosition } from "@angular/cdk/overlay";

export const RAVEN_URL =
	'https://07cace211aa344b0808a95c070db6fcf@sentry.io/198486';

export const tooltipPositions: ConnectedPosition[] = [
	// {
	//    originX: 'start' | 'center' | 'end';
	//    originY: 'top' | 'center' | 'bottom';
	//    overlayX: 'start' | 'center' | 'end';
	//    overlayY: 'top' | 'center' | 'bottom';
	// },
	{
		originX: 'end',
		originY: 'center',
		overlayX: 'start',
		overlayY: 'center',
		panelClass: ['is-after'],
	},
	{
		originX: 'center',
		originY: 'top',
		overlayX: 'center',
		overlayY: 'bottom',
		offsetY: -15,
		panelClass: ['is-above']
	},
];
