import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import locale_extended_fr from '@angular/common/locales/fr';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import 'moment-duration-format';
import { environment } from '../environments/environment';
import { AdminModule } from './admin/admin.module';
import { AuthService } from './admin/services/auth.service';
import { LegalEntitiesService } from './admin/services/legal-entity.service';
import { AppComponent } from './app.component';
import { appInitializer } from './app.initializer';
import { appRouting } from './app.routes';
import { CanActivateAdmin } from './core/canActivate/canActivateAdmin';
import { CanActivateCarAdvisor } from './core/canActivate/canActivateCarAdvisor';
import { createErrorHandler } from '@sentry/angular';

registerLocaleData(locale_extended_fr);

@NgModule({
	declarations: [AppComponent],
	imports: [
		appRouting,
		AdminModule,
		BrowserModule,
		FormsModule,
		HttpClientModule,
		NoopAnimationsModule,
		RouterModule,
	],
	providers: [
		AuthService,
		LegalEntitiesService,
		CanActivateAdmin,
		CanActivateCarAdvisor,
		{ provide: LOCALE_ID, useValue: 'fr-fr' },
		{ provide: ErrorHandler, useValue: createErrorHandler({ logErrors: environment.production }) },
		{ provide: APP_INITIALIZER, useFactory: appInitializer, deps: [AuthService], multi: true }
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
