import { AuthService } from '../services/auth.service';
import { Component } from '@angular/core';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
	constructor(public auth: AuthService) {}

	public get greatMessage(): string {
		if (this.auth.authenticated) {
			let message = `Bonjour ${this.auth.user.name}.`;

			if (this.auth.isAdmin) {
				message += ' Vous êtes administrateur.';
			} else if (this.auth.isCarAdvisor) {
				message += ' Vous êtes un car advisor.';
			} else {
				message += " Vous n'avez aucun droit.";
			}

			return message;
		}

		return 'Bonjour utilisateur inconnu.';
	}
}
