import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap } from 'rxjs/operators';
import {
	IClient,
	IScrapperSearch,
	IWebsiteResult
} from '../../../../../../core/interfaces';
import { IFilter, ScrapperColor, ScrapperEnergy, ScrapperGearbox } from '../../../../../../core/scrapper/interfaces';
import { ClientService } from '../services/client.service';
import { ScrapperService } from '../services/scrapper.service';

export class Option {
	public text: string;
	public value: string;

	public constructor(value: string, text?: string) {
		this.value = value;
		this.text = text || value;
	}
}

@Component({
	selector: 'app-scrapper',
	templateUrl: './scrapper.component.html',
	styleUrls: ['./scrapper.component.scss'],
})
export class ScrapperComponent implements OnInit {
	public form: FormGroup;
	public brandGroup: FormGroup;
	public positionGroup: FormGroup;

	public brandOptions$: Observable<string[]>;
	public modelOptions$: Observable<string[]>;
	public energyOptions: Option[];
	public gearboxOptions: Option[];
	public colorOptions: Option[];

	public results: IWebsiteResult[];
	public searchName: string;

	public client: IClient;
	public searchId: string;
	public searchFilterAsString: string;
	public search: IScrapperSearch;

	public isLoading: boolean;

	public constructor(
		private fb: FormBuilder,
		private scrapperService: ScrapperService,
		private clientService: ClientService,
		private router: Router,
		private route: ActivatedRoute,
	) {
		this.results = [];

		this.energyOptions = [
			new Option(null, 'Toutes'),
			new Option(ScrapperEnergy.Essence),
			new Option(ScrapperEnergy.Diesel),
			new Option(ScrapperEnergy.Electrique, 'Électrique'),
			new Option(ScrapperEnergy.Hybride),
		];

		this.gearboxOptions = [
			new Option(null, 'Toutes'),
			new Option(ScrapperGearbox.Manuelle),
			new Option(ScrapperGearbox.Automatique),
		];

		this.colorOptions = [
			new Option(null, 'Toutes'),
			new Option(ScrapperColor.Argent),
			new Option(ScrapperColor.Beige),
			new Option(ScrapperColor.Blanc),
			new Option(ScrapperColor.Bleu),
			new Option(ScrapperColor.Bordeaux),
			new Option(ScrapperColor.Gris),
			new Option(ScrapperColor.Ivoire),
			new Option(ScrapperColor.Jaune),
			new Option(ScrapperColor.Marron),
			new Option(ScrapperColor.Noir),
			new Option(ScrapperColor.Or),
			new Option(ScrapperColor.Orange),
			new Option(ScrapperColor.Rose),
			new Option(ScrapperColor.Rouge),
			new Option(ScrapperColor.Vert),
			new Option(ScrapperColor.Violet),
		];

		this.isLoading = false;
	}

	public ngOnInit(): void {
		this.initForm();

		this.route.params.subscribe(async params => {
			const searchIdKey = 'searchId';
			const searchId = params[searchIdKey];

			if (searchId) {
				this.searchId = searchId;

				const search = await this.scrapperService.getAlertById(
					searchId,
				);
				this.client = search.client;
				this.searchFilterAsString = search.filtersAsString;

				this.resetSearch();
			}
		});

		this.route.queryParams.subscribe(async params => {
			const clientIdKey = 'clientId';
			const clientId = params[clientIdKey];

			this.client = !!clientId
				? await this.clientService.getOne(clientId)
				: null;
		});
	}

	public initForm(): void {
		const brandFormControl = this.fb.control(null);
		const modelFormControl = this.fb.control({
			value: null,
			disabled: true,
		});
		const versionFormControl = this.fb.control({
			value: null,
			disabled: true,
		});
		const coordinatesFormControl = this.fb.control(null);
		const radiusFormControl = this.fb.control({
			value: null,
			disabled: true,
		});

		this.brandGroup = this.fb.group({
			brand: brandFormControl,
			model: modelFormControl,
		});

		this.positionGroup = this.fb.group({
			coordinates: coordinatesFormControl,
			radius: radiusFormControl,
		});

		this.form = this.fb.group({
			brand: this.brandGroup,
			color: this.fb.control(null),
			gearbox: this.fb.control(null),
			energy: this.fb.control(null),
			minPrice: this.fb.control(null),
			maxPrice: this.fb.control(null),
			maxYear: this.fb.control(null),
			minYear: this.fb.control(null),
			minKilometers: this.fb.control(null),
			maxKilometers: this.fb.control(null),
			position: this.positionGroup,
			version: versionFormControl,
			exclusions: this.fb.control(null),
		});

		coordinatesFormControl.valueChanges.subscribe(coordinates => {
			if (!!coordinates && radiusFormControl.disabled) {
				radiusFormControl.enable();
				radiusFormControl.setValue(50);
			}
			if (!coordinates) {
				radiusFormControl.disable();
				radiusFormControl.setValue(null);
			}
		});

		this.brandOptions$ = brandFormControl.valueChanges
			.pipe(
				debounceTime(300),
				distinctUntilChanged(),
				filter(Boolean),
				switchMap(brand => this.scrapperService.searchBrand(brand)),
			);

			brandFormControl.valueChanges.subscribe(brand => {
				brand
					? modelFormControl.enable()
					: modelFormControl.disable();

				modelFormControl.setValue(null);
			});

		this.modelOptions$ = combineLatest([
			 brandFormControl.valueChanges,
			 modelFormControl.valueChanges,
		]).pipe(
			debounceTime(300),
			filter(([brand, model]) => brand && model),
			switchMap(([brand, model]) => this.scrapperService.searchModel(brand, model)),
		)

		combineLatest([
			brandFormControl.valueChanges,
			modelFormControl.valueChanges,
		]).subscribe(([brand, model]) => {
			!!brand && !!model
				? versionFormControl.enable()
				: versionFormControl.disable();
			versionFormControl.setValue(null);
		});

		this.form.valueChanges.subscribe(
			() =>
				(this.searchName = this.scrapperService.getScrapperSearchName(
					this.form.getRawValue() as IFilter,
				)),
		);
	}

	public get hasFormChanged(): boolean {
		return (
			JSON.stringify(this.form.getRawValue()) !==
			this.searchFilterAsString
		);
	}

	public async submit(): Promise<void> {
		if (this.form.valid && !this.isLoading) {
			this.isLoading = true;

			try {
				const filters = this.getFilters();
				this.results = await this.scrapperService.search(filters);
			} catch (e) {
				//
			}

			this.isLoading = false;
		}
	}

	public async createSearch(): Promise<void> {
		if (this.form.valid && !this.isLoading) {
			this.isLoading = true;

			try {
				const filters = this.getFilters();
				this.search = await this.scrapperService.createSearch({
					filters,
					clientId: '' + this.client.id,
				});

				this.router.navigate(['/clients', this.client.id, '/alerts']);
			} catch (e) {
				//
			}

			this.isLoading = false;
		}
	}

	public async updateSearch(): Promise<void> {
		if (this.form.valid && !this.isLoading && !!this.searchId) {
			this.isLoading = true;

			try {
				const filters = this.getFilters();
				this.search = await this.scrapperService.updateSearch(
					this.searchId,
					filters,
				);

				this.searchFilterAsString = this.search.filtersAsString;
			} catch (e) {
				//
			}

			this.isLoading = false;
		}
	}

	public async resetSearch(): Promise<void> {
		const filters = JSON.parse(this.searchFilterAsString) as IFilter;

		if (filters.brand?.brand) {
			this.form.get('brand').get('model').enable();

			if (filters.brand.model) {
				this.form.get('version').enable();
			}
		}
		if (filters.position) {
			this.form.get('position').get('radius').enable();
		}

		this.form.patchValue(filters, { emitEvent: false });
	}

	private getFilters(): IFilter {
		const result = this.form.getRawValue() as IFilter;
		return result;
	}
}
