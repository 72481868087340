<button mat-raised-button color="primary" (click)="inputFile.click()">Ajouter des images</button>
<button mat-raised-button (click)="displayUrlInput = true">Ajouter une image via un lien</button>
<input #inputFile type="file" class="hidden" (change)="onFileSelected($event)" multiple>

<div class="url-picker" *ngIf="displayUrlInput">
	<mat-form-field>
		<input matInput [formControl]="imageUrl" placeholder="Lien vers une image">
	</mat-form-field>
	<button mat-icon-button>
		<mat-icon matSuffix (click)="addImageFromUrl(imageUrl.value)">add</mat-icon>
	</button>
	<button mat-icon-button>
		<mat-icon matSuffix (click)="displayUrlInput = false">clear</mat-icon>
	</button>
</div>

<div>
	<span *ngFor="let fileToUpload of files" class="image">
		<img [src]="sanitizer.bypassSecurityTrustUrl(fileToUpload.url)" [title]="fileToUpload.name || fileToUpload.file.name" (click)="selectPictures(fileToUpload)"
		 [class.main-picture]="fileToUpload.isMain" />
		<span class="close-button" (click)="removePicture(fileToUpload)">
			<span>X</span>
		</span>
	</span>
	<div>
		<i *ngIf="files.length">Cliquez sur une image pour choisir l'image principale.</i>
	</div>
</div>
