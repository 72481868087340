import { ClientService } from '../../services/client.service';
import { Component } from '@angular/core';
import { IClient } from '../../../../../../../core/interfaces';

@Component({
	selector: 'app-forms',
	templateUrl: './forms.component.html',
	styleUrls: ['./forms.component.scss'],
})
export class FormsComponent {
	public client: IClient;
	public brandOrModels: string;

	public constructor(private clientService: ClientService) {
		this.clientService.client$.subscribe(
			client => {
				this.client = client;

				if (client) {
					this.brandOrModels = [
						...(client.buyForm.vehicules ? client.buyForm.vehicules.split('||') : []),
						client.buyForm.brandOrModel,
					].filter(Boolean).join(', ')
				}
			},
		);
	}
}
