<div class="container" [formGroup]="form">
  <h2>Partenaires</h2>

  <div class="filters-container">
    <mat-form-field class="example-full-width">
      <mat-label>Recherche (3 caractères min.)</mat-label>
      <input type="text" autocomplete="off" formControlName="search" matInput>
    </mat-form-field>
    <mat-form-field>
      <mat-select formControlName="brands" [multiple]="true" placeholder="Marque(s)">
        <mat-option *ngFor="let brand of brands$ | async" [value]="brand">
          {{ brand }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-select formControlName="locations" [multiple]="true" placeholder="Département(s)">
        <mat-option *ngFor="let location of locations$ | async" [value]="location.number">
          {{ location.name }} {{ location.number ? '(' + location.number + ')' : '' }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <ng-container *ngIf="dealerships$ | async as dealerships">
    <div class="dealerships-container" *ngIf="isLoading !== undefined">
      <div class="dealerships-list">
        <div *ngIf="!isLoading && !dealerships.length" class="dealerships-list-empty">
          Aucun partenaire ne correspond.
        </div>
        <mat-spinner class="dealerships-list-loading" [diameter]="30" *ngIf="isLoading"></mat-spinner>
        <div class="dealerships-list-item"
          *ngFor="let dealership of dealerships"
          [class.is-selected]="selected === dealership"
          (click)="selected = dealership">
          {{ dealership.name }}
        </div>
      </div>
      <div class="dealership-container">
        <ng-container *ngIf="selected">
          <h3>{{ selected.name }}</h3>

          <mat-chip-list>
            <mat-chip *ngFor="let brand of selected.brands" selected>
              {{ brand }}
            </mat-chip>
          </mat-chip-list>

          <div class="contact">
            <div *ngIf="selected.contact.name as contact">
              <i class="material-icons">perm_identity</i>
              <span>{{ contact }}</span>
            </div>
            <div *ngIf="selected.contact.phone as phone">
              <i class="material-icons">call</i>
              <span>{{ phone }}</span>
            </div>
            <div *ngIf="selected.contact.mail as mail">
              <i class="material-icons">mail_outline</i>
              <a [href]="'mailto:' + mail">{{ mail }}</a>
            </div>
          </div>

          <div *ngIf="selected.location as location">
            <b>Localisation :</b> {{ location.name }} {{ location.number ? '(' + location.number + ')' : '' }}
          </div>

          <div class="comment">
            <label>Commentaire :</label>
            <p *ngIf="selected.description">{{ selected.description }}</p>
            <p *ngIf="!selected.description"><i>Pas de commentaire.</i></p>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>

