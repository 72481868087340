import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserScope } from '../../../../../core/interfaces';
import { AuthService } from './services/auth.service';

@Component({
	selector: 'app-admin',
	templateUrl: './admin.component.html',
	styleUrls: ['./admin.component.scss'],
})
export class AdminComponent {
	public isSuperAdmin$: Observable<boolean>;

	constructor(private authService: AuthService) {
		this.isSuperAdmin$ = this.authService.principal$.pipe(
			map(principal => principal.scope === UserScope.AllLegalEntities),
		);
	}

	public get isAdmin(): boolean {
		return this.authService.isAdmin;
	}

	public get isCarAdvisor(): boolean {
		return this.authService.isCarAdvisor;
	}
}
