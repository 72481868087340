<table>
	<tr>
		<td>
			<mat-form-field>
				<input matInput [formControl]="versionTokenSearch" [matAutocomplete]="versionsAuto" placeholder="Finitions" #versionInput>
			</mat-form-field>
			<mat-autocomplete #versionsAuto="matAutocomplete">
				<mat-option *ngFor="let option of visibleVersionTokenOptions$ | async" [value]="option" (onSelectionChange)="selectVersionToken(option)">
					{{ option }}
				</mat-option>
			</mat-autocomplete>
		</td>
		<td>
			<mat-chip-list>
				<mat-chip *ngFor="let versionToken of versionTokens$ | async" class="mod-no-line-height">
					<span>{{versionToken}}</span>
					<i class="material-icons" (click)="unselectVersionToken(versionToken)">highlight_off</i>
				</mat-chip>
			</mat-chip-list>
		</td>
	</tr>
</table>
