import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, tap } from 'rxjs/operators';
import { IRecommandation } from '../../../../../../../core/interfaces';
import { RecommandationsService } from '../../services/recommandations.service';

@Component({
	selector: 'app-recommandation-form',
	templateUrl: 'form.component.html',
	styleUrls: ['form.component.scss']
})

export class RecommandationFormComponent implements OnInit {
	form: FormGroup;
	image: string | SafeUrl;
	subscription: Subscription;

	loading = false;
	error = '';

	@ViewChild('fileInput', { static: true }) fileInputRef: ElementRef;

	constructor(
		@Inject(MAT_DIALOG_DATA) public recommandation: IRecommandation,
		private _ref: MatDialogRef<IRecommandation>,
		private _sanitizer: DomSanitizer,
		private _recommandationsService: RecommandationsService,
	) {
		this.form = new FormGroup({
			id: new FormControl(recommandation && recommandation.id),
			model: new FormControl(recommandation && recommandation.model),
			imageUrl: new FormControl(recommandation && recommandation.image, { updateOn: 'blur' }),
			motor: new FormControl(recommandation && recommandation.motor),
			mileage: new FormControl(recommandation && recommandation.mileage),
			year: new FormControl(recommandation && recommandation.year),
			price: new FormControl(recommandation && recommandation.price),
			warranty: new FormControl(recommandation && recommandation.warranty),
			equipment1: new FormControl(recommandation && recommandation.equipments[0]),
			equipment2: new FormControl(recommandation && recommandation.equipments[1]),
			equipment3: new FormControl(recommandation && recommandation.equipments[2]),
		});
		this.image = recommandation && recommandation.image;
		this.subscription = new Subscription();
	}

	ngOnInit() {
		this.subscription.add(
			this.form.valueChanges.pipe(
				tap(() => this.error = null),
				map(val => val.imageUrl),
				distinctUntilChanged(),
				filter(url => !!url)
			).subscribe((url) => {
				this.fileInputRef.nativeElement.value = "";
				this.image = url;
			})
		)
	}

	close(recommandation: IRecommandation = undefined) {
		this._ref.close(recommandation);
	}

	resetImage() {
		if (this.fileInputRef) {
			this.fileInputRef.nativeElement.value = "";
		}
		this.form.patchValue({ imageUrl: null });
		this.image = undefined;
	}

	onImageFileChange() {
		const file = this.getFileInputFile();

		if (file) {
			this.image = this._sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file));
		}
	}

	getFileInputFile() {
		const files = this.fileInputRef && this.fileInputRef.nativeElement.files;

		return files && files.length ? files.item(0) : null;
	}

	async submit() {
		const formData = new FormData();
		const value = this.form.value;

		formData.append('model', value.model);
		formData.append('motor', value.motor);
		formData.append('mileage', value.mileage);
		formData.append('year', value.year);
		formData.append('price', value.price);
		formData.append('warranty', value.warranty);
		formData.append('equipment1', value.equipment1);
		formData.append('equipment2', value.equipment2);
		formData.append('equipment3', value.equipment3);

		if (!this.recommandation || this.recommandation.image !== value.imageUrl) {
			formData.append('imageUrl', value.imageUrl);
		}

		const file = this.getFileInputFile();

		if (file) {
			formData.append('image', file);
		}

		try {
			this.loading = true;

			const promise = this.recommandation
				? this._recommandationsService.update(this.recommandation.id, formData)
				: this._recommandationsService.create(formData);
			const recommandation = await promise;

			this.close(recommandation);
		} catch (error) {
			if (error instanceof HttpErrorResponse) {
				this.error = error.error && error.error.message;
			}
			this.error = this.error || "Une erreur s'est produite";
		} finally {
			this.loading = false;
		}
	}
}
