import {
	ICityPossibility,
	ICityWithCoordinates,
} from '../../../../../../core/scrapper/interfaces';

import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { IEmail } from '../../../../../../core/notification/interfaces';
import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';

@Injectable()
export class GoogleService {
	private _placePreditionSubscription: Subscription;

	constructor(private http: HttpClient, private authService: AuthService) {}

	public async getPlacePredictions(
		search: string,
	): Promise<ICityPossibility[]> {
		return new Promise<ICityPossibility[]>((resolve, reject) => {
			if (this._placePreditionSubscription) {
				this._placePreditionSubscription.unsubscribe();
			}

			const req = this.http.get<ICityPossibility[]>(
				`/api/google/predictions?city=${search}`,
				this.authService.authorizedHeader,
			);

			this._placePreditionSubscription = req.subscribe(
				places => resolve(places),
				err => reject(err),
			);
		});
	}

	public async getPlaceCoordinate(
		placeId: string,
	): Promise<ICityWithCoordinates> {
		return this.http
			.get<ICityWithCoordinates>(
				`/api/google/coordinates?placeId=${placeId}`,
				this.authService.authorizedHeader,
			)
			.toPromise();
	}

	public async getEmail(id: string): Promise<IEmail> {
		return this.http
			.get<IEmail>(
				`/api/google/emails/${id}`,
				this.authService.authorizedHeader,
			)
			.toPromise();
	}
}
